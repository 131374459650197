var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-sm text-gray-700 truncate" },
    [
      _vm.isDate
        ? _c("date-tooltip", {
            staticClass: "date inline-block",
            attrs: { date: _vm.valueToDisplay },
          })
        : _vm.isMonth
        ? _c("p", [
            _vm._v(
              _vm._s(
                _vm._f("applyDateFormatter")(
                  _vm._f("stringToDate")(_vm.valueToDisplay)
                )
              )
            ),
          ])
        : _vm.isEditableBreakdown && _vm.canEdit
        ? _c(
            "router-link",
            { staticClass: "truncate", attrs: { to: _vm.routeToNavigate } },
            [
              _c(
                "span",
                {
                  staticClass: "cursor-pointer hover:underline",
                  attrs: { title: _vm.valueToDisplay },
                },
                [_vm._v(_vm._s(_vm.valueToDisplay))]
              ),
            ]
          )
        : _c("span", { attrs: { title: _vm.valueToDisplay } }, [
            _vm._v(_vm._s(_vm.valueToDisplay || "N/A")),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }