<template>
  <div class="text-sm text-gray-700 truncate">
    <date-tooltip v-if="isDate" class="date inline-block" :date="valueToDisplay" />
    <p v-else-if="isMonth">{{ valueToDisplay | stringToDate | applyDateFormatter }}</p>
    <router-link v-else-if="isEditableBreakdown && canEdit" class="truncate" :to="routeToNavigate">
      <span class="cursor-pointer hover:underline" :title="valueToDisplay">{{ valueToDisplay }}</span>
    </router-link>
    <span v-else :title="valueToDisplay">{{ valueToDisplay || 'N/A' }}</span>
  </div>
</template>

<script>
import { BREAKDOWN_VALUES } from '@/model/modules/ecommerce/analytics/BreakdownValues';
import { applyDateFormatters, stringToDate } from '@/filters/dateFilters';
import { monthDateFormatter } from '@/model/shared/DateFormatter';
import { edit as editOffer } from '@/router/private/modules/ecommerce/demand/offer/edit.js';
import { edit as editPublisher } from '@/router/private/modules/ecommerce/supply/publisher/edit.js';
import { edit as editAdvertiser } from '@/router/private/modules/ecommerce/demand/advertiser/edit.js';

export default {
  name: 'EcommerceBreakdownDisplay',
  components: { DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip') },
  filters: {
    stringToDate,
    applyDateFormatter: date => applyDateFormatters(date, monthDateFormatter),
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    breakdown: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    isSubBreakdown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMonth() {
      return BREAKDOWN_VALUES.month === this.breakdown;
    },
    isDate() {
      return BREAKDOWN_VALUES.day === this.breakdown;
    },
    isEditableBreakdown() {
      return (
        BREAKDOWN_VALUES.offer === this.breakdown ||
        BREAKDOWN_VALUES.publisher === this.breakdown ||
        BREAKDOWN_VALUES.advertiser === this.breakdown
      );
    },
    valueToDisplay() {
      return this.isSubBreakdown ? this.value.subBreakdown : this.value.breakdown;
    },
    routeToNavigate() {
      const itemBreakdownId = this.isSubBreakdown ? this.value.subBreakdownId : this.value.breakdownId;
      if (this.breakdown === BREAKDOWN_VALUES.offer) {
        return { name: editOffer.name, params: { offerId: itemBreakdownId } };
      }
      if (this.breakdown === BREAKDOWN_VALUES.publisher) {
        return { name: editPublisher.name, params: { publisherId: itemBreakdownId } };
      }
      if (this.breakdown === BREAKDOWN_VALUES.advertiser) {
        return { name: editAdvertiser.name, params: { advertiserId: itemBreakdownId } };
      }
      return null;
    },
  },
};
</script>
<style scoped>
::v-deep .date.inline-block {
  @apply inline-block;
}
::v-deep .date.z-10 {
  @apply z-0;
}
</style>
