<template>
  <div class="flex flex-col">
    <div class="flex justify-center items-center h-6 m-2">{{ title }}</div>
    <div v-if="series.length" class="w-full">
      <asterix-donut-chart :options="options" :series="series" />
      <div class="p-2 overflow-auto donut-legend">
        <div v-for="(block, index) in legend" :key="`block_${index}`" class="flex-col border-b legend-item">
          <div class="flex pt-1 justify-between items-end">
            <div class="flex items-center">
              <span class="w-4 h-2 rounded-full mr-1" :style="`background-color:${options.colors[index]};`" />
              <span class="w-full"> {{ block.label }} </span>
            </div>
            <span> {{ block.value }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="w-full">
      <empty-search />
    </div>
  </div>
</template>
<script>
import AsterixDonutChart from '@/components/organisms/shared/asterixCharts/AsterixDonutChart.vue';
import EmptySearch from '@/components/icons/EmptySearch';

export default {
  name: 'WrapperDonutChart',
  components: {
    AsterixDonutChart,
    EmptySearch,
  },
  props: {
    title: {
      default: null,
      type: String,
    },
    options: {
      default: null,
      type: Object,
    },
    series: {
      type: Array,
      default: () => [],
    },
    legend: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
