<template>
  <asterix-section :status="sectionStatus">
    <template #header-right>
      <div>
        <sun-date-picker
          id="filter-date"
          :key="datepickerKey"
          class="w-auto text-sm font-light text-gray-800"
          name="filterDates"
          :value="quickFiltersDate"
          separator="to"
          cancel-error
          show-date-list
          :date-list="dateList"
          required
          :single-date-picker="false"
          :limit-min-date="datePickerMin"
          :limit-max-date="datePickerMax"
          @change="onQuickFiltersDate($event.value)"
        />
      </div>
    </template>
    <template #content>
      <dismissible-background v-if="onSubqueryItem" @close="onSubqueryItem = null" />
      <div v-if="showChart" class="w-full flex pb-8">
        <analytics-chart
          :loading="!isLoadingChart"
          :chart-series="chartSeries"
          :chart-options="chartOptions"
          :date-start="quickFiltersDate.startDate"
          :date-end="quickFiltersDate.endDate"
          :data-type="chartType"
          @change="chartTypeSelected = $event.id"
        >
          <template v-if="isChartCurrencyValue" #total="{ total }">
            {{ total | currency() }}
          </template>
        </analytics-chart>
      </div>

      <sun-filter-layout
        id="filter-box"
        class="mb-5"
        :filters-added="filterFind"
        :filters-available="availableFilters"
        :has-error="anyError"
        show-reset
        show-reset-button
        hide-back-button
        @close="filtersResetErrors"
        @remove="filtersResetErrors"
        @reset="resetFilters"
        @reset-selection="resetSelection"
        @change="addFilters"
      >
        <template #quickFilters>
          <div class="flex flex-wrap min-w-max">
            <div class="m-1">
              <sun-search-input
                :value="filterQuick['breakdown']"
                class-input="text-sm"
                :disabled="isQuickSearchDisabled"
                @search="onQuickFiltersSearch($event, 'breakdown')"
              />
            </div>
          </div>
        </template>
        <template #[`filter.countryIso`]="{ filter, onSelect, value, isDuplicate }">
          <sun-select
            :id="`filter_${filter.name}`"
            class="filter-select"
            class-input="pt-0 rounded-none multiselect"
            add-hex-color="orange"
            label="name"
            multiple
            :max-height="350"
            :options="countries"
            :close-on-select="false"
            :track-by="filter.trackBy"
            :reseted="resetedSeveralValueFilters"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event, onSelect)"
            @blur="closeSelect"
            @focus="forceSelectHeight"
            @reseted="resetedSeveralValueFilters = false"
          ></sun-select>
        </template>
        <template #[`filter.publisherId`]="{ filter, onSelect, value, isDuplicate }">
          <asterix-async-select
            :id="`filter_${filter.name}`"
            name="publisher-select-filter"
            :service="filter.service"
            :track-by="filter.trackBy"
            :max-height="350"
            color-tag="green"
            label="name"
            class="filter-select"
            :close-on-select="false"
            multiple
            :reseted="resetedSeveralValueFilters"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event, onSelect)"
            @focus="forceSelectHeight"
            @blur="closeSelect"
            @reseted="resetedSeveralValueFilters = false"
          />
        </template>
        <template #[`filter.offerId`]="{ filter, onSelect, value, isDuplicate }">
          <asterix-async-select
            :id="`filter_${filter.name}`"
            name="select-offer"
            :track-by="filter.trackBy"
            :max-height="350"
            color-tag="green"
            label="name"
            class="filter-select"
            add-hex-color="orange"
            :close-on-select="false"
            multiple
            :reseted="resetedSeveralValueFilters"
            :service="filter.service"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event, onSelect)"
            @focus="forceSelectHeight"
            @blur="closeSelect"
            @reseted="resetedSeveralValueFilters = false"
          />
        </template>
        <template #[`filter.advertiserId`]="{ filter, onSelect, value, isDuplicate }">
          <asterix-async-select
            :id="`filter_${filter.name}`"
            name="select-advertiser"
            :track-by="filter.trackBy"
            :max-height="350"
            color-tag="green"
            label="name"
            add-hex-color="orange"
            class="filter-select"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :close-on-select="false"
            multiple
            :reseted="resetedSeveralValueFilters"
            :items-per-page="100"
            :service="filter.service"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event, onSelect)"
            @focus="forceSelectHeight"
            @blur="closeSelect"
            @reseted="resetedSeveralValueFilters = false"
          />
        </template>
        <template #[`filter.campaignId`]="{ filter, onSelect, value, isDuplicate }">
          <asterix-async-select
            :id="`filter_${filter.name}`"
            name="select-campaign"
            :track-by="filter.trackBy"
            :max-height="350"
            color-tag="green"
            class="filter-select"
            add-hex-color="orange"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :close-on-select="false"
            multiple
            :reseted="resetedSeveralValueFilters"
            :service="filter.service"
            :items-per-page="100"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event, onSelect)"
            @focus="forceSelectHeight"
            @blur="closeSelect"
            @reseted="resetedSeveralValueFilters = false"
          />
        </template>
        <template #[`filter.subPublisherCategory`]="{ filter, onSelect, isDuplicate }">
          <sun-input
            type="text"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
          />
        </template>
      </sun-filter-layout>
      <div class="flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center">
        <div class="flex flex-row items-center mb-2 lg:pr-1 lg:mb-0">
          <columns-selector-ecommerce :default-columns="columnsSelectorOptions" @change="updateVisibleHeaders" />
        </div>

        <div class="h-10 mb-2 breakdown lg:mb-0 flex items-center">
          <breakdown-wrapper
            v-model="breakdown"
            label="Select Breakdowns"
            :items="breakdownOptions"
            :disabled="breakdownDisabled"
            horizontal-grow
            hide-secondary
            @reset="resetBreakdown"
          />
        </div>
        <div class="flex flex-row items-center mb-2 ml-1 lg:mb-0">
          <sun-popover v-if="!logButtonAvailable && showLogButton" :text-info="popOverLogButton" position="top">
            <button class="px-4 py-2 bg-white rounded opacity-25" disabled>
              <log-file-svg class="w-5 mr-1" />
            </button>
          </sun-popover>
          <button
            v-else-if="showLogButton"
            class="px-4 py-2 bg-white hover:bg-gray-300 rounded"
            :disabled="isLoading || reportToastLoading || !logButtonAvailable"
            @click="showLogReportModal = true"
          >
            <log-file-svg class="w-5 mr-1" />
          </button>
        </div>

        <div class="flex flex-row items-center mb-2 ml-1 lg:mb-0">
          <asterix-dropdown
            button-classes="flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center"
            :disabled="isLoading || reportToastLoading"
            :options="reportOptions"
            @change="handlerReport"
          >
            <span class="block w-5 mr-2">
              <csv-file-svg />
            </span>
            <template #dropdown-header>
              <p class="p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap">Download as</p>
            </template>
            <template #icon="state">
              <span class="transition-100" :class="!state.visible ? 'rotate-180' : ''">
                <up-svg class="w-2 h-2" :class="state.disabled ? 'text-gray-400' : 'text-gray-500'" />
              </span>
            </template>
          </asterix-dropdown>
        </div>
      </div>

      <div class="flex flex-col w-full mb-8">
        <sun-data-table
          :content="items"
          :detailed="!!breakdown.secondary"
          expand-all-detail-button
          :headers="availableHeadersWithoutPostEvent"
          hoverable
          striped
          :loading="isLoading"
          @expandAllDetail="expandAllDetail"
          @expandDetail="expandDetail"
          @sort="wrapperOnSortTable"
        >
          <template v-for="column in tablePostEventColumns" #[`col.${column.name}`]="{ item, columnClass }">
            <sun-data-table-cell :key="column.name" :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell
                v-if="item.postEvents && item.postEvents[column.property]"
                :value="item.postEvents[column.property]"
              >
                <template #accumulated="{ value }"> {{ value.total }} </template>
              </ecommerce-analytics-valid-invalid-cell>
              <span v-else>
                <span class="text-green-600">0</span>
                <span class="mx-2">-</span>
                <span class="text-red-600">0</span>
              </span>
            </sun-data-table-cell>
          </template>
          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :ref="`colActions${item.id}`" :class="columnClass">
              <table-action-menu
                :actions="actions"
                :item="item"
                :items="items"
                @click="onActionClick($event, item)"
                @dotsClicked="subQueryMenuActive = item"
              />
              <teleport v-if="item === subQueryMenuActive" :visible="true" :position="actionCellPosition">
                <template v-if="showSubqueryMenu(item)">
                  <div
                    class="flex rounded-lg shadow-md transition-100 flex-col absolute z-100 bg-gray-800 p-3 text-gray-300 min-w-full whitespace-no-wrap overflow-y-scroll max-h-220"
                    :class="subQueryMenuClasses"
                  >
                    <span
                      v-for="option in breakdownOptions.items"
                      :key="option.name"
                      class="text-left cursor-pointer w-full px-3 text-xs font-bold text-gray-500 hover:text-white hover:bg-gray-700 rounded-full"
                      @click="onPrimarySubquery(option.value)"
                    >
                      {{ option.name }}
                    </span>
                  </div>
                </template>
              </teleport>
            </sun-data-table-cell>
          </template>
          <template #[`col.breakdown`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass" sticky :detail-cell="!breakdown.secondary" width="300">
              <ecommerce-breakdown-display :value="item" :breakdown="breakdown.primary" :can-edit="roleCanEdit" />
              <p
                v-if="isOfferBreakdown && !item.campaignIsAutogenerated"
                class="text-xs font-bold text-gray-600 truncate"
              >
                {{ item.campaignName }}
              </p>
            </sun-data-table-cell>
          </template>
          <template #[`col.publisherName`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <p class="text-xs text-gray-600 truncate">{{ item.publisherName }}</p>
            </sun-data-table-cell>
          </template>

          <template #[`col.clicksAccumulated`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <div
                v-if="item.clicks.blocked > 0"
                @click.stop.prevent="validInvalidClick(item, blockedTypes.CLICK, $event)"
              >
                <ecommerce-analytics-valid-invalid-cell :value="item.clicks" class="cursor-pointer" />
                <dismissible-background v-if="item.clkReason" @close="closeInvalidReason(item, blockedTypes.CLICK)" />
              </div>
              <ecommerce-analytics-valid-invalid-cell v-else :value="item.clicks" />
              <teleport v-if="item.clkReason" :visible="true" :position="reasonCellPosition">
                <div
                  class="bg-white flex rounded-lg shadow-md transition-100 flex-col absolute z-100 text-xs"
                  :class="reasonDisplayClasses"
                >
                  <analytics-blocked-reasons
                    :filters-applied="filterFind"
                    :entity="item"
                    :event-type="blockedTypes.CLICK"
                    :breakdown="breakdown.primary"
                    :date-range="quickFiltersDate"
                  />
                </div>
              </teleport>
            </sun-data-table-cell>
          </template>
          <template #[`col.clicks`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.clicks" only-valid />
            </sun-data-table-cell>
          </template>
          <template #[`col.clicksBlocked`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.clicks" only-blocked />
            </sun-data-table-cell>
          </template>

          <template #[`col.conversionsAccumulated`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <div
                v-if="item.conversions.blocked > 0"
                @click.stop.prevent="validInvalidClick(item, blockedTypes.CONVERSION, $event)"
              >
                <ecommerce-analytics-valid-invalid-cell
                  :value="item.conversions"
                  class="cursor-pointer"
                  :only-accumulated="isAdvertiserUser"
                  color-accumulated="green"
                />
                <dismissible-background
                  v-if="item.conv_pevReason"
                  @close="closeInvalidReason(item, blockedTypes.CONVERSION)"
                />
              </div>
              <ecommerce-analytics-valid-invalid-cell
                v-else
                :value="item.conversions"
                :only-accumulated="isAdvertiserUser"
                color-accumulated="green"
              />

              <teleport v-if="item.conv_pevReason" :visible="true" :position="reasonCellPosition">
                <div
                  class="bg-white flex rounded-lg shadow-md transition-100 flex-col absolute z-100 text-xs"
                  :class="reasonDisplayClasses"
                >
                  <analytics-blocked-reasons
                    :filters-applied="filterFind"
                    :entity="item"
                    :event-type="blockedTypes.CONVERSION"
                    :breakdown="breakdown.primary"
                    :date-range="quickFiltersDate"
                  />
                </div>
              </teleport>
            </sun-data-table-cell>
          </template>
          <template #[`col.conversions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.conversions" only-valid />
            </sun-data-table-cell>
          </template>
          <template #[`col.conversionsBlocked`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.conversions" only-blocked />
            </sun-data-table-cell>
          </template>

          <template #[`col.impressionsAccumulated`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <div
                v-if="item.impressions.blocked > 0"
                @click.stop.prevent="validInvalidClick(item, blockedTypes.IMPRESSION, $event)"
              >
                <ecommerce-analytics-valid-invalid-cell :value="item.impressions" class="cursor-pointer" />
                <dismissible-background
                  v-if="item.impReason"
                  @close="closeInvalidReason(item, blockedTypes.IMPRESSION)"
                />
              </div>
              <ecommerce-analytics-valid-invalid-cell v-else :value="item.impressions" />
              <teleport v-if="item.impReason" :visible="true" :position="reasonCellPosition">
                <template>
                  <div
                    class="bg-white flex rounded-lg shadow-md transition-100 flex-col absolute z-100 text-xs"
                    :class="reasonDisplayClasses"
                  >
                    <analytics-blocked-reasons
                      :filters-applied="filterFind"
                      :entity="item"
                      :event-type="blockedTypes.IMPRESSION"
                      :breakdown="breakdown.primary"
                      :date-range="quickFiltersDate"
                    />
                  </div>
                </template>
              </teleport>
            </sun-data-table-cell>
          </template>
          <template #[`col.impressions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.impressions" only-valid />
            </sun-data-table-cell>
          </template>
          <template #[`col.impressionsBlocked`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.impressions" only-blocked />
            </sun-data-table-cell>
          </template>

          <template #[`col.leadsAccumulated`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <div
                v-if="item.leads.blocked > 0"
                @click.stop.prevent="validInvalidClick(item, blockedTypes.LEADS, $event)"
              >
                <ecommerce-analytics-valid-invalid-cell
                  :value="item.leads"
                  class="cursor-pointer"
                  :only-accumulated="isAdvertiserUser"
                  color-accumulated="green"
                />
                <dismissible-background v-if="item.convReason" @close="closeInvalidReason(item, blockedTypes.LEADS)" />
              </div>
              <ecommerce-analytics-valid-invalid-cell
                v-else
                :value="item.leads"
                :only-accumulated="isAdvertiserUser"
                color-accumulated="green"
              />

              <teleport v-if="item.convReason" :visible="true" :position="reasonCellPosition">
                <template>
                  <div
                    class="bg-white flex rounded-lg shadow-md transition-100 flex-col absolute z-100 text-xs"
                    :class="reasonDisplayClasses"
                  >
                    <analytics-blocked-reasons
                      :filters-applied="filterFind"
                      :entity="item"
                      :event-type="blockedTypes.LEADS"
                      :breakdown="breakdown.primary"
                      :date-range="quickFiltersDate"
                    />
                  </div>
                </template>
              </teleport>
            </sun-data-table-cell>
          </template>
          <template #[`col.leads`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.leads" only-valid />
            </sun-data-table-cell>
          </template>
          <template #[`col.leadsBlocked`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.leads" only-blocked />
            </sun-data-table-cell>
          </template>

          <template #[`col.orderValueAccumulated`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell
                :value="item.orderValue"
                filter-name="currency"
                :only-accumulated="isAdvertiserUser"
              />
            </sun-data-table-cell>
          </template>
          <template #[`col.orderValue`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.orderValue" filter-name="currency" only-valid />
            </sun-data-table-cell>
          </template>
          <template #[`col.orderValueBlocked`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <ecommerce-analytics-valid-invalid-cell :value="item.orderValue" filter-name="currency" only-blocked />
            </sun-data-table-cell>
          </template>

          <template #[`col.revenue`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">{{ item.revenue | currency }}</sun-data-table-cell>
          </template>
          <template #[`col.grossRevenue`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">{{ item.grossRevenue | currency }}</sun-data-table-cell>
          </template>
          <template #[`col.expense`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">{{ item.expense | currency }}</sun-data-table-cell>
          </template>
          <template #[`col.profit`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">{{ item.profit | currency }}</sun-data-table-cell>
          </template>
          <template #[`col.cr`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <div class="flex flex-col items-center">
                <span class="font-bold">1 : {{ item.crRate }}</span
                ><span>({{ item.cr | percentage }})</span>
              </div>
            </sun-data-table-cell>
          </template>
          <template #[`col.url`]="{ item, columnClass }">
            <sun-data-table-cell class="text-center" :class="columnClass">
              {{ item.url || '-' }}
            </sun-data-table-cell>
          </template>
          <template #[`col.category`]="{ item, columnClass }">
            <sun-data-table-cell class="text-center" :class="columnClass">{{
              item.category || '-'
            }}</sun-data-table-cell>
          </template>
          <template #detailed="{ item }">
            <sun-data-table-row v-for="(detail, $iRow) in item.children" :key="$iRow + '_' + item.id">
              <sun-data-table-cell sticky detail-cell />
              <template v-for="(column, $index) in availableHeadersWithoutPostEvent">
                <template v-if="column && column.visible">
                  <sun-data-table-cell :key="`${$index}_${column}`" :class="[`text-${column.align}`]">
                    <span v-if="column.value === COLUMNS.ACTIONS.value" />
                    <template v-else-if="column.value === COLUMNS.BREAKDOWN.value">
                      <ecommerce-breakdown-display
                        :can-edit="roleCanEdit"
                        :value="detail"
                        :breakdown="breakdown.secondary"
                        is-sub-breakdown
                      />
                    </template>

                    <template
                      v-else-if="
                        [
                          COLUMNS.IMPRESSIONS.value,
                          COLUMNS.CLICKS.value,
                          COLUMNS.CONVERSIONS.value,
                          COLUMNS.LEADS.value,
                        ].includes(column.value)
                      "
                    >
                      <ecommerce-analytics-valid-invalid-cell :value="detail[COLUMNS_VALID_BLOCKED[column.value]]" />
                    </template>

                    <template
                      v-else-if="
                        [
                          COLUMNS.IMPRESSIONS_VALID.value,
                          COLUMNS.CLICKS_VALID.value,
                          COLUMNS.CONVERSIONS_VALID.value,
                          COLUMNS.LEADS_VALID.value,
                        ].includes(column.value)
                      "
                    >
                      <span class="text-green-600">
                        <ecommerce-analytics-valid-invalid-cell
                          :value="detail[COLUMNS_VALID_BLOCKED[column.value]]"
                          only-valid
                        />
                      </span>
                    </template>
                    <template
                      v-else-if="
                        [
                          COLUMNS.IMPRESSIONS_BLOCKED.value,
                          COLUMNS.CLICKS_BLOCKED.value,
                          COLUMNS.CONVERSIONS_BLOCKED.value,
                          COLUMNS.LEADS_BLOCKED.value,
                        ].includes(column.value)
                      "
                    >
                      <ecommerce-analytics-valid-invalid-cell
                        :value="detail[COLUMNS_VALID_BLOCKED[column.value]]"
                        only-blocked
                      />
                    </template>

                    <template v-else-if="COLUMNS.ORDER_VALUE.value === column.value">
                      <ecommerce-analytics-valid-invalid-cell
                        :value="detail[COLUMNS_VALID_BLOCKED[column.value]]"
                        filter-name="currency"
                      />
                    </template>
                    <template v-else-if="COLUMNS.ORDER_VALUE_VALID.value === column.value">
                      <ecommerce-analytics-valid-invalid-cell
                        :value="detail[COLUMNS_VALID_BLOCKED[column.value]]"
                        filter-name="currency"
                        only-valid
                      />
                    </template>
                    <template v-else-if="COLUMNS.ORDER_VALUE_BLOCKED.value === column.value">
                      <ecommerce-analytics-valid-invalid-cell
                        :value="detail[COLUMNS_VALID_BLOCKED[column.value]]"
                        filter-name="currency"
                        only-blocked
                      />
                    </template>

                    <template
                      v-else-if="
                        [COLUMNS.REVENUE.value, COLUMNS.EXPENSE.value, COLUMNS.PROFIT.value].includes(column.value)
                      "
                    >
                      <p>
                        {{ detail[column.value] | currency }}
                      </p>
                    </template>
                    <template v-else-if="COLUMNS.CR.value === column.value">
                      <p>{{ detail[COLUMNS.CR.value].toFixed(2) }}%</p>
                    </template>
                    <template v-else-if="hasPostEventsDetail(column)">
                      <ecommerce-analytics-valid-invalid-cell
                        v-if="detail.postEvents"
                        :value="getPostEventDetails(column, detail)"
                      >
                        <template #accumulated="{ value }"> {{ value.total }} </template>
                      </ecommerce-analytics-valid-invalid-cell>
                    </template>
                    <template v-else-if="COLUMNS.GROSS_REVENUE.value === column.value">
                      {{ detail[COLUMNS.GROSS_REVENUE.value] | currency }}
                    </template>
                    <p v-else>- {{ tablePostEventColumns }}</p>
                  </sun-data-table-cell>
                </template>
              </template>
            </sun-data-table-row>
            <asterix-table-row-load-more
              v-if="canGetMoreItems(item) && !detailPagination.getError(item.id)"
              :loading="detailPagination.isLoading(item.id)"
              :cols="availableHeaders.length"
              @click="handleNextDetail(item)"
            />
            <sun-data-table-row v-if="!item.children.length">
              <sun-data-table-cell detail-cell class="bg-gray-300" sticky />
              <sun-data-table-cell :colspan="headers.length" class="text-left bg-gray-300 italic text-gray-700">
                <p class="ml-16 text-left">No data found for this option</p>
              </sun-data-table-cell>
            </sun-data-table-row>
          </template>
          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>

          <template v-if="totals" #footer>
            <template v-for="column in availableHeadersWithoutPostEvent">
              <template v-if="column.value === COLUMNS.BREAKDOWN.value">
                <sun-data-table-cell
                  :key="`footer_${column.name}_totals`"
                  class="w-full border-t-2 bg-gray-400"
                  sticky
                  style="left: 0 !important; border-right: 0 !important"
                >
                  <div class="font-bold uppercase pl-2" data-testid="name-total-cell">Total</div>
                </sun-data-table-cell>
                <sun-data-table-cell
                  v-if="columnsByBreakdown.length > 0"
                  :key="`footer_${column.name}_totals_1`"
                  class="w-full"
                />
              </template>

              <sun-data-table-cell
                v-if="column.value === COLUMNS.IMPRESSIONS.value && column.visible"
                :key="`footer_${column.name}_impressions`"
              >
                <ecommerce-analytics-valid-invalid-cell
                  :value="{
                    valid: totals.impressions,
                    blocked: totals.impressionsBlocked,
                    accumulated: totals.impressionsAccumulated,
                  }"
                />
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.IMPRESSIONS_VALID.value && column.visible"
                :key="`footer_${column.name}_impressionValid`"
                class="text-center"
              >
                {{ totals.impressions }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.IMPRESSIONS_BLOCKED.value && column.visible"
                :key="`footer_${column.name}_impressionsBlocked`"
                class="text-center"
              >
                {{ totals.impressionsBlocked }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.CLICKS.value && column.visible"
                :key="`footer_${column.name}_clicksAccumulated`"
              >
                <ecommerce-analytics-valid-invalid-cell
                  :value="{
                    valid: totals.clicks,
                    blocked: totals.clicksBlocked,
                    accumulated: totals.clicksAccumulated,
                  }"
                />
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.CLICKS_VALID.value && column.visible"
                :key="`footer_${column.name}_clicks`"
                class="text-center"
              >
                {{ totals.clicks }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.CLICKS_BLOCKED.value && column.visible"
                :key="`footer_${column.name}_clicksBlocked`"
                class="text-center"
              >
                {{ totals.clicksBlocked }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.CONVERSIONS.value && column.visible"
                :key="`footer_${column.name}_conversionsAccumulated`"
              >
                <ecommerce-analytics-valid-invalid-cell
                  :value="{
                    valid: totals.conversions,
                    blocked: totals.conversionsBlocked,
                    accumulated: totals.conversionsAccumulated,
                  }"
                  :only-accumulated="isAdvertiserUser"
                />
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.CONVERSIONS_VALID.value && column.visible"
                :key="`footer_${column.name}_conversions`"
                class="text-center"
              >
                {{ totals.conversions }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.CONVERSIONS_BLOCKED.value && column.visible"
                :key="`footer_${column.name}_conversionsBlocked`"
                class="text-center"
              >
                {{ totals.conversionsBlocked }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.LEADS.value && column.visible"
                :key="`footer_${column.name}_leadsAccumulated`"
              >
                <ecommerce-analytics-valid-invalid-cell
                  :value="{
                    valid: totals.leads,
                    blocked: totals.leadsBlocked,
                    accumulated: totals.leadsAccumulated,
                  }"
                  :only-accumulated="isAdvertiserUser"
                />
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.LEADS_VALID.value && column.visible"
                :key="`footer_${column.name}_leads`"
                class="text-center"
              >
                {{ totals.leads }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.LEADS_BLOCKED.value && column.visible"
                :key="`footer_${column.name}_leadsBlocked`"
                class="text-center"
              >
                {{ totals.leadsBlocked }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.ORDER_VALUE.value && column.visible"
                :key="`footer_${column.name}_order_value_accumulated`"
              >
                <ecommerce-analytics-valid-invalid-cell
                  :value="{
                    valid: totals.orderValue,
                    blocked: totals.orderValueBlocked,
                    accumulated: totals.orderValueAccumulated,
                  }"
                  :only-accumulated="isAdvertiserUser"
                  filter-name="currency"
                />
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.ORDER_VALUE_VALID.value && column.visible"
                :key="`footer_${column.name}_order_value`"
                class="text-center"
              >
                {{ totals.orderValue }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.ORDER_VALUE_BLOCKED.value && column.visible"
                :key="`footer_${column.name}_order_value_blocked`"
                class="text-center"
              >
                {{ totals.orderValueBlocked }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.REVENUE.value && column.visible"
                :key="`footer_${column.name}_revenue`"
                class="text-right pr-4"
              >
                {{ totals.revenue | currency }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.GROSS_REVENUE.value && column.visible"
                :key="`footer_${column.name}_gross_revenue`"
                class="text-right pr-4"
              >
                {{ totals.grossRevenue | currency }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.EXPENSE.value && column.visible"
                :key="`footer_${column.name}_expense`"
                class="text-right pr-4"
              >
                {{ totals.expense | currency }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.PROFIT.value && column.visible"
                :key="`footer_${column.name}_profit`"
                class="text-right pr-4"
              >
                {{ totals.profit | currency }}
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.CR.value && column.visible"
                :key="`footer_${column.name}_cr`"
              >
                <div class="flex flex-col items-center">
                  <span class="font-bold">1 : {{ totals.crRate }}</span
                  ><span>({{ totals.cr | percentage }})</span>
                </div>
              </sun-data-table-cell>
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.CATEGORY.value && column.visible"
                :key="`footer_${column.name}_cr`"
                class="w-full border-t-2 bg-gray-400"
              />
              <sun-data-table-cell
                v-else-if="column.value === COLUMNS.URL.value && column.visible"
                :key="`footer_${column.name}_cr`"
                class="w-full border-t-2 bg-gray-400"
              />
            </template>
            <sun-data-table-cell v-for="column in tablePostEventColumns" :key="`footer_${column.name}_postEvent`">
              <ecommerce-analytics-valid-invalid-cell
                v-if="totals.postEvents && totals.postEvents[column.property]"
                :value="totals.postEvents[column.property]"
              >
                <template #accumulated="{ value }"> {{ value.total }} </template>
              </ecommerce-analytics-valid-invalid-cell>
              <span v-else class="px-4">
                <span class="text-green-600">0</span>
                <span class="mx-2">-</span>
                <span class="text-red-600">0</span>
              </span>
            </sun-data-table-cell>
            <sun-data-table-cell v-if="showActionsColumn" class="w-full border-t-2 bg-gray-400"> </sun-data-table-cell>
          </template>
        </sun-data-table>
      </div>

      <sun-pagination-page
        class="mb-8"
        :total-pages="totalPages"
        :total-items="totalItems"
        :current-page="currentPage"
        :value="itemsPerPage"
        :options-per-page="availablePageSizes"
        @changePage="changePage"
        @changePerPage="changeItemsPerPage"
      />

      <report-log-modal
        v-if="showLogReportModal"
        :user-role="contextUserRole"
        @close="onCloseReportLogModal"
        @download="onDownloadLogReport"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ANALYTICS_REPORT_TOAST, SET_ANALYTICS_REPORT_TOAST } from '@/store/modules/analyticsReportToast/keys';
import AsterixSection from '@/components/templates/AsterixSection';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import { indexMixin } from '@/mixins/index/indexMixin';
import { COLUMNS, CONFIG, CHART_CONFIG, COLUMNS_VALID_BLOCKED } from './config';
import { USER } from '@/store/modules/auth/keys';
import { BREAKDOWN, SAVE_BREAKDOWN, SAVE_FILTERS } from '@/store/modules/filters/keys';
import { COUNTRY_NS, COUNTRIES_KEY, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import ColumnsSelectorEcommerce from '@/components/molecules/shared/columnsSelector/ColumnsSelectorEcommerce.vue';
import BreakdownWrapper from '@/components/molecules/modules/Analytics/Breakdown/BreakdownWrapper';
import {
  createAnalyticsReport,
  getAnalytics,
  getAnalyticsTotals,
  getBreakdownAnalytics,
  createAnalyticsRowDataReport,
} from '@/services/modules/ecommerce/analytics';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import metaInfo from '@/mixins/common/metaInfo';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import Breakdown from '@/model/shared/Breakdown';
import AnalyticsChart from '@/components/organisms/shared/asterixCharts/AnalyticsChart';
import {
  BREAKDOWN_VALUES,
  BREAKDOWN_SORT_KEY,
  BREAKDOWN_QUICK_FILTER_KEY,
} from '@/model/modules/ecommerce/analytics/BreakdownValues';
import EcommerceBreakdownDisplay from '@/components/molecules/modules/ecommerce/analytics/EcommerceBreakdownDisplay';
import EcommerceAnalyticsValidInvalidCell from '@/components/molecules/modules/ecommerce/analytics/EcommerceAnalyticsValidInvalidCell';
import { edit as editOffer } from '@/router/private/modules/ecommerce/demand/offer/edit.js';
import { show as showOffer } from '@/router/private/modules/ecommerce/demand/offer/show.js';
import { edit as editPublisher } from '@/router/private/modules/ecommerce/supply/publisher/edit.js';
import { edit as editAdvertiser } from '@/router/private/modules/ecommerce/demand/advertiser/edit.js';
import getAnalyticsReport from '@/services/modules/ecommerce/analytics/getAnalyticsReport';
import getAnalyticsRowDataReport from '@/services/modules/ecommerce/analytics/getAnalyticsRowDataReport';
import getAnalyticsRowDataReportStatus from '@/services/modules/ecommerce/analytics/getAnalyticsRowDataReportStatus';
import getAnalyticsReportStatus from '@/services/modules/ecommerce/analytics/getAnalyticsReportStatus';
import { stringToDate } from '@/filters/dateFilters';
import apiRequest from '@/utils/apiRequest';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import Column from '@/model/shared/Column';
import AsterixTableRowLoadMore from '@/components/molecules/shared/AsterixTableRowLoadMore';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ReportParamsBuilder from '@/model/modules/ecommerce/analytics/ReportParamsBuilder';
import DetailPagination from '@/model/shared/analytics/DetailPagination';
import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig';
import { CHART_TYPES } from '@/components/organisms/shared/asterixCharts/config';
import DismissibleBackground from '@/components/atoms/DismissibleBackground';
import Teleport from '@/components/organisms/shared/Teleport.vue';
import { deepClone } from '@/utils/deepClone';
import LogFileSvg from '@/components/icons/LogFileSvg.vue';
import CsvFileSvg from '@/components/icons/CsvFileSvg.vue';
import AnalyticsBlockedReasons from '@/components/molecules/modules/ecommerce/analytics/AnalytcsBlockedReasons.vue';

const DAY_IN_MILLISECONDS = 86400000;

export default {
  name: 'Analytics',
  components: {
    AnalyticsChart,
    AsterixAsyncSelect,
    AsterixDropdown,
    AsterixSection,
    BreakdownWrapper,
    ColumnsSelectorEcommerce,
    EcommerceBreakdownDisplay,
    EcommerceAnalyticsValidInvalidCell,
    AsterixTableRowLoadMore,
    DismissibleBackground,
    Teleport,
    LogFileSvg,
    CsvFileSvg,
    AnalyticsBlockedReasons,
    UpSvg: () => import('@/components/icons/UpSvg.vue'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    ReportLogModal: () => import('@/components/molecules/modules/ecommerce/analytics/ReportLogModal'),
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        breakdown: undefined,
        range_start: undefined,
        range_end: undefined,
      },
    }),
    indexMixin,
    metaInfo,
  ],
  data: () => ({
    currentApi: new EcommerceApi(),
    isLoading: true,
    isLoadingChart: true,
    breakdown: { primary: BREAKDOWN_VALUES.offer, secondary: null },
    previousBreakdown: null,
    datepickerKey: 0,
    sectionStatus: SECTION_STATUS.OK,
    items: [],
    headers: CONFIG.defaultColumns,
    availablePageSizes: [50, 100, 200],
    selectedColumnConfig: null,
    reportOptions: [
      { id: 'CSV', name: 'CSV', value: 'CSV' },
      { id: 'XLS', name: 'XLS', value: 'XLS' },
    ],
    defaultDate: CONFIG.defaultDate,
    dateList: CONFIG.dateList,
    cacheDetails: new Map(),
    chartLabelFormatters: CHART_CONFIG.chartLabelFormattersByBreakdown,
    chartData: {},
    chartOptions: CHART_CONFIG.chartOptions,
    chartTypeSelected: CHART_CONFIG.chartDataType.CLICKS,
    detailPagination: new DetailPagination(500),
    showPostEvents: true,
    tablePostEventColumns: [],
    updateChart: true,
    onSubqueryItem: null,
    actionCellPosition: { x: 0, y: 0 },
    reasonCellPosition: { x: 0, y: 0 },
    subQueryMenuClasses: null,
    reasonDisplayClasses: null,
    subQueryMenuActive: null,
    datePickerMax: CONFIG.limitMaxDate,
    datePickerMin: CONFIG.limitMinDate,
    showLogReportModal: false,
    blockedTypes: CONFIG.blockedTypes,
    totals: null,
    resetedSeveralValueFilters: false,
  }),
  computed: {
    COLUMNS: () => COLUMNS,
    BREAKDOWN_VALUES: () => BREAKDOWN_VALUES,
    COLUMNS_VALID_BLOCKED_KEYS: () => Object.keys(COLUMNS_VALID_BLOCKED),
    COLUMNS_VALID_BLOCKED: () => COLUMNS_VALID_BLOCKED,
    ...mapState(COUNTRY_NS, {
      countries: COUNTRIES_KEY,
    }),
    ...mapGetters({
      user: USER,
      getStoredBreakdown: BREAKDOWN,
    }),
    isAdminUser() {
      return this.contextUserRole === ROLES.ADMIN.id;
    },
    isAdopsUser() {
      return this.contextUserRole === ROLES.AD_OPS.id;
    },
    isPublisherUser() {
      return this.contextUserRole === ROLES.PUBLISHER.id;
    },
    isClientUser() {
      return this.contextUserRole === ROLES.CLIENT.id;
    },
    isAdvertiserUser() {
      return [ROLES.SUPER_ADVERTISER.id, ROLES.ADVERTISER.id].includes(this.contextUserRole);
    },
    isClientOrAdvertiserUser() {
      return this.contextUserRole === ROLES.CLIENT.id || this.isAdvertiserUser;
    },
    isMonth() {
      return this.breakdown.primary === BREAKDOWN_VALUES.month;
    },
    isHour() {
      return this.breakdown.primary === BREAKDOWN_VALUES.hour;
    },
    showLogButton() {
      return this.isAdminUser || this.isAdopsUser;
    },
    logButtonAvailable() {
      const offerOrAdvertiserFilterApplied = this.filterFind.some(
        filter => filter.name === 'offerId' || filter.name === 'advertiserId'
      );
      if (this.isAdopsUser) {
        const conversions = this.items.reduce((count, item) => count + item.conversions.accumulated, 0);
        return offerOrAdvertiserFilterApplied && conversions > 0;
      }
      return offerOrAdvertiserFilterApplied;
    },
    actions() {
      // retornar acciones dependiendo del breakdown
      const actions = [{ name: 'Subquery' }];

      if (
        (this.breakdown.primary === BREAKDOWN_VALUES.offer ||
          this.breakdown.primary === BREAKDOWN_VALUES.advertiser ||
          this.breakdown.primary === BREAKDOWN_VALUES.publisher) &&
        [ROLES.ADMIN.id, ROLES.AD_OPS.id, ROLES.PUBLISHER.id].includes(this.contextUserRole)
      ) {
        actions.unshift({ name: this.isPublisherUser ? 'Show' : 'Edit' });
      }

      return actions;
    },
    showActionsColumn() {
      const hasActions = [
        BREAKDOWN_VALUES.offer,
        BREAKDOWN_VALUES.publisher,
        BREAKDOWN_VALUES.advertiser,
        BREAKDOWN_VALUES.day,
        BREAKDOWN_VALUES.month,
      ];
      return hasActions.includes(this.breakdown.primary);
    },
    storedBreakdown() {
      return this.getStoredBreakdown(this.$route.name);
    },
    isQuickSearchDisabled() {
      return this.breakdown.primary !== BREAKDOWN_VALUES.offer && this.breakdown.primary !== BREAKDOWN_VALUES.publisher;
    },
    reportToastLoading() {
      return !!this.$store.getters[ANALYTICS_REPORT_TOAST]?.id;
    },
    contextUserRole() {
      return this.user?.contextRoles.find(c => c.context === CONTEXTS.ECOMMERCE.id).role;
    },
    chartType() {
      if (this.breakdown.primary === BREAKDOWN_VALUES.hour) return CHART_TYPES.HOUR;
      if (this.breakdown.primary === BREAKDOWN_VALUES.month) return CHART_TYPES.MONTH;
      return CHART_TYPES.DAY;
    },
    defaultSort() {
      switch (this.breakdown.primary) {
        case this.BREAKDOWN_VALUES.hour:
          return { field: CONFIG.tableSortOptions.HOUR, order: 'desc' };
        case this.BREAKDOWN_VALUES.day:
          return { field: CONFIG.tableSortOptions.DAY, order: 'desc' };
        case this.BREAKDOWN_VALUES.month:
          return { field: CONFIG.tableSortOptions.MONTH, order: 'desc' };
        default:
          return { field: CONFIG.tableSortOptions.REVENUE, order: 'desc' };
      }
    },
    columnsByRole() {
      return this.headers.filter(
        column => !column.rolesAllowed.length || column.rolesAllowed.includes(this.contextUserRole)
      );
    },
    columnsByBreakdown() {
      if (
        this.breakdown.primary === BREAKDOWN_VALUES.pubId &&
        COLUMNS.PUBLISHER.rolesAllowed.includes(this.contextUserRole)
      ) {
        return [COLUMNS.PUBLISHER];
      }
      return [];
    },
    roleCanEdit() {
      const rolesAllowedEdit = [ROLES.ADMIN.id, ROLES.AD_OPS.id];
      return rolesAllowedEdit.includes(this.contextUserRole);
    },
    canShowPostEvents() {
      return this.filterFind.filter(filter => filter.filterConfig === FILTERS_CONFIG.OFFER_ID).length === 1;
    },
    columnsSelectorOptions() {
      const postEventColumn = [];
      const allColumnsByRole = CONFIG.allColumns.filter(
        column => !column.rolesAllowed.length || column.rolesAllowed.includes(this.contextUserRole)
      );
      if (this.isPublisherUser || this.isClientOrAdvertiserUser)
        allColumnsByRole.forEach(header => (header.visible = true));
      if (this.canShowPostEvents) {
        postEventColumn.push(COLUMNS.POST_EVENTS);
      } else if (allColumnsByRole.find(col => col === COLUMNS.POST_EVENTS)) {
        return allColumnsByRole.filter(col => col !== COLUMNS.POST_EVENTS);
      }
      const newColumns = allColumnsByRole.concat(postEventColumn);

      newColumns.sort(
        (col1, col2) =>
          this.availableHeadersWithoutPostEvent.findIndex(({ name }) => name === col1.name) -
          this.availableHeadersWithoutPostEvent.findIndex(({ name }) => name === col2.name)
      );
      newColumns.forEach(column => {
        column.visible = !!this.availableHeadersWithoutPostEvent.find(({ name }) => name === column.name)?.visible;
      });

      return newColumns;
    },
    availableHeaders() {
      const columns = [];
      const postEventColumns = [];
      if (this.actions.length && this.showActionsColumn) {
        columns.push(new Column(COLUMNS.ACTIONS.title, COLUMNS.ACTIONS.value).setClass('relative bg-gray-400'));
      }
      if ([BREAKDOWN_VALUES.pubId, BREAKDOWN_VALUES.subPublisherCategory].includes(this.breakdown.primary)) {
        columns.push(COLUMNS.URL);
      }
      if (BREAKDOWN_VALUES.pubId === this.breakdown.primary) {
        columns.push(COLUMNS.CATEGORY);
      }
      if (this.canShowPostEvents && this.showPostEvents) {
        this.items.forEach(item => {
          if (item.postEvents) {
            Object.keys(item.postEvents).forEach(key => {
              const colName = key.split(' ').join('');
              const postEventColum = this.tablePostEventColumns.find(col => col.name === colName);
              if (!postEventColum) {
                this.tablePostEventColumns.push({
                  name: colName,
                  property: key,
                });
                postEventColumns.push(
                  new Column(key.toUpperCase(), key.split(' ').join(''))
                    .setAllowedRoles(COLUMNS.POST_EVENTS.rolesAllowed)
                    .setClass(COLUMNS.POST_EVENTS.class)
                );
              } else if (!postEventColumns.find(col => col.title === key.toUpperCase())) {
                postEventColumns.push(
                  new Column(postEventColum.property.toUpperCase(), postEventColum.name)
                    .setAllowedRoles(COLUMNS.POST_EVENTS.rolesAllowed)
                    .setClass(COLUMNS.POST_EVENTS.class)
                );
              }
            });
          }
        });
      }

      const breakdownColumn = {
        ...COLUMNS.BREAKDOWN,
        name: this.primaryBreakdownName,
        sort: this.decodeSortFieldName(this.sort?.field) === 'breakdown' ? this.sort?.order : true,
      };
      return [breakdownColumn]
        .concat(this.columnsByBreakdown)
        .concat(this.columnsByRole)
        .concat(postEventColumns)
        .concat(columns);
    },
    availableHeadersWithoutPostEvent() {
      return this.availableHeaders.filter(header => header.value !== COLUMNS.POST_EVENTS.value);
    },
    breakdownOptions() {
      let filteredByRole = CONFIG.breakdown.items.filter(f => f.rolesAllowed.includes(this.contextUserRole));
      filteredByRole = filteredByRole.map(option => {
        const optionItems = option.items;
        if (optionItems) {
          optionItems.items = optionItems.items.filter(
            item => !item.rolesAllowed || item.rolesAllowed.includes(this.contextUserRole)
          );
        }
        return { ...option, items: optionItems };
      });
      const breakdownComputed = CONFIG.breakdown;
      breakdownComputed.items = filteredByRole;
      return breakdownComputed;
    },
    subBreakdownSort() {
      const breakdown = this.breakdownOptions.items.find(breakdown => breakdown.value === this.breakdown.primary);
      const subBreakdown = breakdown?.items.items.find(subBreakdown => subBreakdown.value === this.breakdown.secondary);
      return subBreakdown?.sort || this.defaultSort.field;
    },
    primaryBreakdownName() {
      const primaryFormatted = CONFIG.breakdown.items.find(b => b.value === this.breakdown.primary);
      return primaryFormatted?.name?.toUpperCase();
    },
    quickFiltersDate() {
      const rangeStart = this.filterQuick.range_start || this.defaultDate.startDate;
      const rangeEnd = this.filterQuick.range_end || this.defaultDate.endDate;

      const startDate = stringToDate(rangeStart);
      const endDate = stringToDate(rangeEnd);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 0);

      return { startDate, endDate };
    },
    isOfferBreakdown() {
      return this.breakdown.primary === BREAKDOWN_VALUES.offer;
    },
    showAdvertiserNameOnCell() {
      return BREAKDOWN_VALUES.offer === this.breakdown.primary;
    },
    showAdvertiserNameOnDetailCell() {
      return BREAKDOWN_VALUES.offer === this.breakdown.secondary;
    },
    isChartCurrencyValue() {
      return this.chartTypeSelected === CHART_CONFIG.chartDataType.REVENUE;
    },
    chartSeries() {
      const numberOfDecimals = this.isChartCurrencyValue ? 2 : 0;

      return [
        {
          name: this.chartTypeSelected,
          type: 'line',
          data: this.chartData?.[this.chartTypeSelected] || new Map(),
          yaxisConfigExtend: { labels: { formatter: val => val.toFixed(numberOfDecimals) } },
          xaxisConfigExtend: { labels: { formatter: this.chartLabelFormatters[this.breakdown.primary] } },
        },
      ];
    },
    breakdownDisabled() {
      return this.isLoading || this.detailPagination.loadingItems.length > 0;
    },
    isBreakdownTemp() {
      const breakdowns = [BREAKDOWN_VALUES.day, BREAKDOWN_VALUES.month, BREAKDOWN_VALUES.hour];
      return breakdowns.includes(this.breakdown.primary);
    },
    showChart() {
      return this.totalItems > 1 && this.isBreakdownTemp;
    },
    popOverLogButton() {
      return this.isAdopsUser
        ? 'It is necessary to filter by an advertiser or by one or more offers And conversions must be > 0'
        : 'It is necessary to filter by an advertiser or by one or more offers';
    },
  },
  watch: {
    breakdown: {
      deep: true,
      handler: 'handlerBreakdown',
    },
    showChart() {
      this.chartTypeSelected = CHART_CONFIG.chartDataType.CLICKS;
    },
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
    this.setBreakdown();
  },
  async mounted() {
    await this.getCountriesRequest();
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();
    this.setDefaultDateFilter();

    await this.getTableItems();
    await this.getTotals();

    await this.filtersUpdateFiltersOnStoreAndURL();
    if (this.isPublisherUser) this.headers = CONFIG.defaultPublisherColumns;
    if (this.isAdvertiserUser) this.headers = CONFIG.defaultAdvertiserColumns;

    this.updateHeadersSortState();
  },
  beforeDestroy() {
    this.resetFiltersBeforeDestroy();
  },
  methods: {
    ...mapActions({
      showReportToast: SET_ANALYTICS_REPORT_TOAST,
      createToast: CREATE_TOAST,
      getCountriesRequest: GET_COUNTRIES_REQUEST,
      saveBreakdown: SAVE_BREAKDOWN,
      SAVE_FILTERS,
    }),
    // need this to force the selector's filter height so it doesn't cover the buttons below
    forceSelectHeight() {
      // const filterElement = document.querySelector('.filter');
      // if (filterElement) filterElement.classList.add('force-height');
    },
    closeSelect() {
      const filterElement = document.querySelector('.filter');
      setTimeout(() => {
        if (filterElement) filterElement.classList.remove('force-height');
      }, 300);
    },
    onActionClick(event, item) {
      if (event.name === 'Show') {
        this.$router.push({ name: showOffer.name, params: { offerId: item.breakdownId } });
      }
      if (event.name === 'Edit') {
        let route = {};
        switch (this.breakdown.primary) {
          case BREAKDOWN_VALUES.offer:
            route = { name: editOffer.name, params: { offerId: item.breakdownId } };
            break;
          case BREAKDOWN_VALUES.advertiser:
            route = { name: editAdvertiser.name, params: { advertiserId: item.breakdownId } };
            break;
          default:
            route = { name: editPublisher.name, params: { publisherId: item.breakdownId } };
            break;
        }
        this.$router.push(route);
      }
      if (event.name === 'Subquery') {
        const itemPosition = this.items.indexOf(item);
        const middlePosition = this.items.length / 2;
        const cellPosition = this.$refs[`colActions${item.id}`].getBoundingClientRect();
        if (itemPosition >= middlePosition) {
          this.subQueryMenuClasses = 'options-left-top';
          this.actionCellPosition = {
            x: window.scrollX + cellPosition.x,
            y: window.scrollY + cellPosition.y + cellPosition.height,
          };
        } else {
          this.subQueryMenuClasses = 'options-left-bottom';

          this.actionCellPosition = { x: window.scrollX + cellPosition.x, y: window.scrollY + cellPosition.y };
        }

        this.onSubqueryItem = item;
      }
    },
    async onPrimarySubquery(option) {
      let filterToApply;
      let fullDate;
      let year;
      let month;
      let day;
      let startDate;
      let endDate;
      switch (this.breakdown.primary) {
        case BREAKDOWN_VALUES.offer:
          filterToApply = FILTERS_CONFIG.OFFER_ID;
          break;
        case BREAKDOWN_VALUES.publisher:
          filterToApply = FILTERS_CONFIG.PUBLISHER_ID;
          break;
        case BREAKDOWN_VALUES.advertiser:
          filterToApply = FILTERS_CONFIG.ANALYTICS_ADVERTISER_ID;
          break;
        case BREAKDOWN_VALUES.day:
          fullDate = this.onSubqueryItem.breakdown.split('-');
          year = parseInt(fullDate[0]);
          month = parseInt(fullDate[1]);
          day = parseInt(fullDate[2]);

          startDate = new Date(year, month - 1, day);
          endDate = new Date(year, month - 1, day);

          this.setFilterQuick({ startDate, endDate });
          await this.filtersUpdateFiltersOnStoreAndURL();
          break;
        case BREAKDOWN_VALUES.month:
          fullDate = this.onSubqueryItem.breakdown.split('-');
          year = parseInt(fullDate[0]);
          month = parseInt(fullDate[1]);

          endDate = new Date(year, month, 0);
          startDate = new Date(year, month - 1, 1);

          this.setFilterQuick({ startDate, endDate });
          await this.filtersUpdateFiltersOnStoreAndURL();
          break;
        default:
          break;
      }
      if (
        this.breakdown.primary === BREAKDOWN_VALUES.publisher ||
        this.breakdown.primary === BREAKDOWN_VALUES.advertiser ||
        this.breakdown.primary === BREAKDOWN_VALUES.offer
      ) {
        this.filterFind = this.filterFind.filter(filter => filter.name !== filterToApply.value);
        this[SAVE_FILTERS]([
          {
            filterConfig: filterToApply,
            name: filterToApply.name,
            value: this.onSubqueryItem.breakdownId,
          },
        ]);
        this.filtersLoadFromStorefilters();
      }
      if (this.breakdown.primary === option) {
        await this.getTableItems();
        await this.getTotals();
      }
      this.$set(this.breakdown, 'primary', option);

      this.onSubqueryItem = null;
    },
    showSubqueryMenu(item) {
      if (!this.onSubqueryItem) return false;
      return this.onSubqueryItem.breakdown === item.breakdown;
    },
    hasPostEventsDetail(column) {
      return this.tablePostEventColumns.findIndex(col => col.name === column.value) >= 0;
    },
    getPostEventDetails(column, detail) {
      return detail.postEvents[this.tablePostEventColumns.find(col => col.name === column.value).property];
    },
    async chartInit() {
      await apiRequest(async () => {
        const auxParams = this.setParamsToChart();
        const { data } = await getAnalytics(this.activeClient.id, auxParams);
        this.formatDataToSeriesChart(data);
      }).catch(() => {
        this.createToast(Toast.error('Analytics', 'We can not get chart data.'));
      });
    },
    formatDataToSeriesChart(rawData) {
      const { CLICKS, CONVERSIONS, REVENUE } = CHART_CONFIG.chartDataType;
      const chartData = {
        [CLICKS]: new Map(),
        [CONVERSIONS]: new Map(),
        [REVENUE]: new Map(),
      };

      rawData.forEach(chartPoint => {
        const key = chartPoint.breakdown;
        chartData[CLICKS].set(key, chartPoint[CLICKS].accumulated);
        chartData[CONVERSIONS].set(key, chartPoint[CONVERSIONS].accumulated);
        chartData[REVENUE].set(key, chartPoint[REVENUE]);
      });
      this.chartData = chartData;
    },

    setBreakdown() {
      const isBreakdownValid = breakdown => Object.values(BREAKDOWN_VALUES).includes(breakdown) && breakdown;

      const retrievedPrimary = isBreakdownValid(this.queryParams.breakdown) || this.storedBreakdown?.primary;
      this.queryParams.breakdown = retrievedPrimary;
      const retrievedSecondary = isBreakdownValid(this.queryParams.subBreakdown) || this.storedBreakdown?.secondary;
      this.queryParams.subBreakdown = retrievedSecondary;

      this.breakdown = {
        primary: retrievedPrimary || BREAKDOWN_VALUES.offer,
        secondary: retrievedSecondary,
      };
    },
    changePage(page) {
      this.goToPage({ page });
    },
    addFilters(filters) {
      const allFilters = [];
      const filtersToDelete = [];
      filters.forEach(filter => {
        const filterConfig = filter.filterConfig;
        const label = filter.label;
        const name = filter.name;
        const filterMeta = filter.meta.value || filter.meta.items || filter.meta;
        if (typeof filterMeta === 'string') {
          allFilters.push({
            filterConfig,
            label,
            meta: filterMeta,
            name,
            value: filterMeta,
          });
        } else if (filterMeta?.length) {
          filterMeta.forEach(filterValue => {
            allFilters.push({
              filterConfig,
              label,
              meta: filterValue,
              name,
              value: filterValue[filterConfig.textBy],
            });
          });
        } else if (filterMeta?.length === 0) {
          filtersToDelete.push(name);
        } else {
          allFilters.push({
            filterConfig,
            label,
            meta: filterMeta,
            name,
            value: filterMeta[filterConfig.textBy],
          });
        }
      });

      this.changeFilters(allFilters.filter(filter => !filtersToDelete.includes(filter.name)));
    },
    async changeFilters(filters) {
      await this.updateFilters(filters);
      await this.getTableItems();
      await this.getTotals();
    },
    async updateFilters(filters = []) {
      this.replaceQueryParams({
        page: 1,
        breakdown: this.queryParams.breakdown || BREAKDOWN_VALUES.offer,
        subBreakdown: this.queryParams.subBreakdown || undefined,
      });

      this.filterFind = filters;
      await this.filtersUpdateFiltersOnStoreAndURL();
    },

    /**
     * @param {QueryParamsBuilder} params
     * @returns  {QueryParamsBuilder}
     */
    wrapperSetParamsToTable(params) {
      const auxParams = this.setParamsToTable(params);
      const breakdownFilter = BREAKDOWN_QUICK_FILTER_KEY[this.breakdown.primary];
      if (breakdownFilter) {
        auxParams.addFilter(breakdownFilter, auxParams.removeFilter('breakdown'));
      }
      return auxParams;
    },

    async getTableItems() {
      this.tablePostEventColumns = [];
      this.isLoading = true;
      if (this.updateChart) this.isLoadingChart = true;
      const { isCancel } = await apiRequest(async () => {
        this.cacheDetails.clear();

        this.items = [];
        this.sectionStatus = SECTION_STATUS.OK;
        this.meta = null;
        const auxParams = this.wrapperSetParamsToTable();

        auxParams.page = this.currentPage;
        auxParams.itemsPerPage = this.itemsPerPage;

        auxParams.setBreakdown(this.breakdown.primary);
        const { data, meta } = await getAnalytics(this.activeClient.id, auxParams, { preventCancel: true });
        this.items = data;
        this.totalPages = meta.totalPages || 1;
        this.totalItems = meta.totalResults || this.items.length;
        if (this.updateChart && this.isBreakdownTemp) await this.chartInit();
        this.updateChart = true;
      }).catch(() => {
        this.sectionStatus = SECTION_STATUS.ERROR;
      });
      if (!isCancel) {
        this.isLoading = false;
        this.isLoadingChart = false;
      }
    },
    updateVisibleHeaders(columns) {
      const postEvent = columns.find(col => col.name === COLUMNS.POST_EVENTS.name);
      if (postEvent !== undefined) {
        this.showPostEvents = postEvent.visible;
      } else {
        this.showPostEvents = true;
      }
      columns.forEach(column => {
        const header = this.headers.find(h => h.name === column.name);
        if (header) {
          header.visible = column.visible;
        }
      });
      this.headers = columns;
    },
    closeAllDetailItems() {
      this.items.forEach(item => {
        this.closeDetailItem(item.id);
      });
    },
    closeDetailItem(id) {
      if (this.cacheDetails.has(id)) {
        const detailItem = this.cacheDetails.get(id);
        detailItem.opened = false;
      }
    },
    openDetailItemInCache(id) {
      const detailItem = this.cacheDetails.get(id);
      detailItem.opened = true;
    },
    buildDetailParams(page, itemsPerPage) {
      const pageParams = new QueryParamsBuilder(page, itemsPerPage);
      const auxParams = this.wrapperSetParamsToTable(pageParams);

      if (
        (this.sort && Object.values(BREAKDOWN_SORT_KEY).includes(this.sort?.field)) ||
        this.breakdown.secondary === BREAKDOWN_VALUES.day ||
        !this.sort
      ) {
        auxParams.addSort(this.subBreakdownSort.field, this.subBreakdownSort.order);
      }

      auxParams.setBreakdown(this.breakdown.primary);
      auxParams.setSubBreakdown(this.breakdown.secondary);

      return auxParams;
    },
    addFiltersToParams(params, item) {
      if (this.breakdown.primary === BREAKDOWN_VALUES.day) {
        const date = stringToDate(item.breakdownId);
        params.addFilter('range_start', date.setHours(0, 0, 0, 0));
        params.addFilter('range_end', date.setHours(23, 59, 59, 1000));
      } else if (this.breakdown.primary === BREAKDOWN_VALUES.month) {
        params.addFilter(this.breakdown.primary, item.breakdownId);
      } else if (this.breakdown.primary === BREAKDOWN_VALUES.creative_type) {
        params.addFilter('creativeType', item.breakdownId);
      } else {
        params.addFilter(`${this.breakdown.primary}Id`, item.breakdownId);
      }
    },
    validInvalidClick(item, type, event) {
      const itemPosition = this.items.indexOf(item);
      const middlePosition = this.items.length / 2;
      const cellPosition = event.target.getBoundingClientRect();
      if (itemPosition >= middlePosition) {
        this.reasonDisplayClasses = 'options-left-top';
        this.reasonCellPosition = {
          x: window.scrollX + cellPosition.x,
          y: window.scrollY + cellPosition.y + cellPosition.height,
        };
      } else {
        this.reasonDisplayClasses = 'options-left-bottom';

        this.reasonCellPosition = { x: window.scrollX + cellPosition.x, y: window.scrollY + cellPosition.y };
      }
      this.$set(item, `${type}Reason`, true);
    },
    closeInvalidReason(item, type) {
      this.$set(item, `${type}Reason`, false);
    },
    addDetailToCache(item, index) {
      this.cacheDetails.set(item.id, {
        breakdownId: item.breakdownId,
        index,
        totalResults: this.detailPagination.getItemsPerPage(),
        opened: true,
      });
    },
    prepareAllDetailRequests() {
      const params = this.buildDetailParams(1, this.detailPagination.getItemsPerPage());
      const promises = [];
      const indexItemsPromises = [];

      this.items.forEach((item, index) => {
        if (this.cacheDetails.has(item.id)) {
          this.openDetailItemInCache(item.id);
        } else {
          this.detailPagination.addItem(item.id);
          this.detailPagination.setLoading(item.id, true);
          this.addFiltersToParams(params, item);
          promises.push(getBreakdownAnalytics(this.activeClient.id, params));
          indexItemsPromises.push(index);
        }
      });

      return [promises, indexItemsPromises];
    },
    async getAllDetailChildren(promises) {
      try {
        return await Promise.all(promises);
      } catch {
        this.createToast(
          Toast.error(`Oops! We couldn't get the data`, 'Please try again later or review the filters.')
        );
      } finally {
        this.detailPagination.endLoadingAll();
      }
    },
    processAllDetailResponses(allChildren, indexItemsPromises) {
      if (allChildren.length === 0) return;

      let promisesResultIndex = 0;
      this.items.forEach((item, index) => {
        if (indexItemsPromises.includes(index)) {
          const childrenReponse = allChildren[promisesResultIndex];
          this.processChildrenResponse(item, childrenReponse, index);

          promisesResultIndex++;
        }
      });
    },
    processChildrenResponse(item, childrenReponse, index) {
      const { data, meta } = childrenReponse;

      this.$set(item, 'children', data);
      this.detailPagination.setMaxPage(item.id, meta.totalPages);
      this.addDetailToCache(item, index);
    },
    async expandAllDetail(expand) {
      if (!expand) {
        this.closeAllDetailItems();
        return;
      }

      const [promises, indexItemsPromises] = this.prepareAllDetailRequests();
      const allChildren = await this.getAllDetailChildren(promises);
      this.processAllDetailResponses(allChildren, indexItemsPromises);
    },
    async expandDetail({ index, item, expand }, currentItemPage = 1) {
      this.detailPagination.addItem(item.id);

      const isDetailInCache = this.cacheDetails.has(item.id);
      if (isDetailInCache) {
        const detailItem = this.cacheDetails.get(item.id);
        detailItem.opened = expand;
      }

      if (expand && (!isDetailInCache || currentItemPage > 1)) {
        const params = this.buildDetailParams(currentItemPage, this.detailPagination.getItemsPerPage());
        this.addFiltersToParams(params, item);

        let children = [];
        try {
          this.detailPagination.setLoading(item.id, true);
          const { data, meta } = await getBreakdownAnalytics(this.activeClient.id, params);
          children = data;
          children.forEach(child => {
            delete child.id;
            delete child.breakdown;
            delete child.breakdownId;
          });
          this.detailPagination.setMaxPage(item.id, meta.totalPages);
        } catch {
          children = [];
          this.detailPagination.setError(item.id);
          this.createToast(
            Toast.error(`Oops! We couldn't get the data`, 'Please try again later or review the filters.')
          );
        } finally {
          this.detailPagination.setLoading(item.id, false);
        }

        const totalResults = this.detailPagination.getCurrentPage(item.id) * this.detailPagination.getItemsPerPage();
        this.cacheDetails.set(item.id, { breakdownId: item.breakdownId, index, totalResults, opened: true });

        if (currentItemPage > 1) {
          children = this.items[index].children.concat(children);
        }
        this.$set(this.items[index], 'children', children);
      }
    },
    handleNextDetail(item) {
      const nextPage = this.detailPagination.nextPage(item.id);
      if (!nextPage) return;

      const index = this.items.findIndex(row => row.id === item.id);
      if (index >= 0) {
        this.expandDetail({ index, item, expand: true }, nextPage);
      }
    },
    closeDetails() {
      this.cacheDetails.forEach(({ index }) => {
        this.$set(this.items[index], 'children', undefined);
        this.$set(this.items[index], 'openChildren', false);
      });
      this.cacheDetails.clear();
    },
    updateHeadersSortState() {
      const name = this.decodeSortFieldName(this.sort?.field);
      this.availableHeaders.forEach(header => {
        if (header.value === name) {
          header.sort = this.sort?.order?.toLowerCase() || true;
        } else if (header.sort) {
          header.sort = true;
        }
      });
    },
    decodeSortFieldName(field) {
      return Object.values(BREAKDOWN_SORT_KEY).includes(field) ? COLUMNS.BREAKDOWN.value : field;
    },
    encodeSortFieldName(field) {
      const breakdownSort = BREAKDOWN_SORT_KEY[this.breakdown.primary];
      return field === COLUMNS.BREAKDOWN.value ? breakdownSort : field;
    },
    setParamsToChart() {
      const auxParams = this.wrapperSetParamsToTable(
        new QueryParamsBuilder(this.currentPage, this.chartTotalItemsToGet)
      );
      auxParams.addSort(this.breakdown.primary);
      auxParams.setBreakdown(this.breakdown.primary);
      return auxParams;
    },

    async onQuickFiltersDate(value) {
      this.setFilterQuick(value || this.defaultDate);
      await this.changeFilters(this.filterFind);
    },
    setFilterQuick({ startDate, endDate }) {
      endDate.setHours(23, 59, 59, 0);
      startDate.setHours(0, 0, 0, 0);
      this.filterQuick.range_start = startDate.toISOString();
      this.filterQuick.range_end = endDate.toISOString();

      if (this.breakdown.primary === BREAKDOWN_VALUES.hour) {
        this.chartTotalItemsToGet = 25;
      } else {
        const milliseconds = (endDate.getTime() - startDate.getTime()) / DAY_IN_MILLISECONDS;
        this.chartTotalItemsToGet = milliseconds.toFixed(0) + 1;
      }

      // TODO: Is this necessary?
      this.datepickerKey++;
    },
    setDefaultDateFilter() {
      this.setFilterQuick(this.quickFiltersDate);
    },

    wrapperOnSortTable({ key, sort }) {
      this.onSortTable({ key: this.encodeSortFieldName(key), sort });
      this.updateChart = false;
    },

    resetBreakdown() {
      this.breakdown = new Breakdown(BREAKDOWN_VALUES.offer);
    },
    async handlerBreakdown(value) {
      const { primary, secondary } = value;
      const breakdownColumn = this.headers.find(c => c.value === COLUMNS.BREAKDOWN.value);
      if (breakdownColumn?.name) {
        breakdownColumn.name = this.primaryBreakdownName;
      }

      this.addQueryParams({ breakdown: primary });
      if (secondary) {
        this.addQueryParams({ subBreakdown: secondary });
      } else {
        this.removeQueryParam('subBreakdown');
      }

      this.saveBreakdown({ idView: this.$route.name, breakdown: value });

      const isFirstTime = !this.previousBreakdown;
      const hasBreakdownPrimaryChanged = this.previousBreakdown?.primary !== value?.primary;
      const hasBreakdownSecondaryChanged = this.previousBreakdown?.secondary !== value?.secondary;
      const hasBreakdownChanged = hasBreakdownPrimaryChanged || hasBreakdownSecondaryChanged;

      if (hasBreakdownChanged && !isFirstTime) {
        this.filterQuick.breakdown = undefined;
        await this.queryParamsRouterReplace();
      }

      if (hasBreakdownPrimaryChanged && !isFirstTime) {
        this.removeQueryParam('sort');
        this.eventOrder(this.defaultSort);
        await this.queryParamsRouterReplace();
      }
      if (!isFirstTime) {
        this.detailPagination.reset();
        this.updateHeadersSortState();
        await this.getTableItems();
        await this.getTotals();
      }
      this.previousBreakdown = deepClone(value);
    },
    async handlerReport({ value: format }) {
      try {
        const auxParams = this.wrapperSetParamsToTable();

        const config = {
          sort: auxParams.sort,
          filter: {
            ...auxParams.filters,
            breakdown: this.filterQuick?.breakdown,
          },
          format,
          breakdown: this.breakdown.primary,
          subBreakdown: this.breakdown.secondary,
          columns: this.columnsByRole.filter(c => c.visible).map(c => c.value),
          unfolded_items: this.getUnfoldedItems(),
        };
        if (this.canShowPostEvents && this.showPostEvents) {
          config.columns.push(COLUMNS.POST_EVENTS.value);
        }
        const {
          data: { id: reportId },
        } = await createAnalyticsReport(this.activeClient.id, config);

        if (reportId) {
          this.showReportToast({
            id: reportId,
            format: format.toLowerCase(),
            client: this.activeClient,
            reportEndpoints: { getReport: getAnalyticsReport, getReportStatus: getAnalyticsReportStatus },
          });
        }
      } catch (error) {
        this.createToast(Toast.error('Analytics', 'An error occurred while generating the report'));
      }
    },
    canGetMoreItems(item) {
      return this.detailPagination.isLoading(item.id) || !this.detailPagination.isLastPage(item.id);
    },
    getUnfoldedItems() {
      const unfolded_items = [];
      this.cacheDetails.forEach(value => {
        if (value.opened) {
          unfolded_items.push({
            breakdownId: value.breakdownId,
            totalResults: value.totalResults,
          });
        }
      });
      return unfolded_items;
    },
    setParamsToReport(event) {
      const dateRange = {
        start: this.filterQuick.range_start,
        end: this.filterQuick.range_end,
      };
      const columns = event.cols.map(col => col.value);
      const auxParams = new ReportParamsBuilder('CSV', event.type, dateRange, {}, columns);
      this.filterFind.forEach(filter => {
        if (auxParams.hasFilter(CONFIG.filtersNameToLogReport[filter.filterConfig.key] || filter.name)) {
          auxParams.filter[CONFIG.filtersNameToLogReport[filter.filterConfig.key] || filter.name].push(filter.meta.id);
        } else {
          auxParams.addFilter(CONFIG.filtersNameToLogReport[filter.filterConfig.key] || filter.name, [filter.meta.id]);
        }
      });
      return auxParams;
    },
    onCloseReportLogModal() {
      this.showLogReportModal = false;
    },
    async onDownloadLogReport(event) {
      try {
        const auxParams = this.setParamsToReport(event);
        const response = await createAnalyticsRowDataReport(this.activeClient.id, auxParams);
        this.onCloseReportLogModal();

        if (response.id) {
          this.showReportToast({
            id: response.id,
            format: 'csv',
            client: this.activeClient,
            reportEndpoints: { getReport: getAnalyticsRowDataReport, getReportStatus: getAnalyticsRowDataReportStatus },
          });
        }
      } catch {
        this.createToast(Toast.error('Analytics', 'An error occurred while generating the report'));
        this.onCloseReportLogModal();
      }
    },
    async filtersOnActiveClientChange() {
      this.resetSort();
      this.filterQuick.range_start = null;
      this.filterQuick.range_end = null;
      this.setDefaultDateFilter();
      this.resetBreakdown();
      this.updateVisibleHeaders(this.columnsSelectorOptions);
      await this.updateFilters();
    },
    async getTotals() {
      try {
        this.isLoading = true;
        const auxParams = this.wrapperSetParamsToTable();
        auxParams.page = this.currentPage;
        auxParams.itemsPerPage = this.itemsPerPage;

        const { data } = await getAnalyticsTotals(this.activeClient.id, auxParams);
        this.totals = data;
      } catch (e) {
        this.createToast(Toast.error('Analytics', 'An error occurred while loading totals row'));
      } finally {
        this.isLoading = false;
      }
    },
    resetSelection() {
      this.resetedSeveralValueFilters = true;
    },
  },
};
</script>

<style scoped>
.breakdown {
  min-width: 280px;
}

::v-deep .button-analytics {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #4a5568;
  min-height: 35px;
  border-radius: 0.25rem !important;
  background-color: #e2e8f0;
  padding: 0 1rem;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(3, 1, 1, 0.06) !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

::v-deep .button-analytics:hover {
  background-color: #cbd5e0;
}

.options-left-bottom {
  right: 100%;
  top: 0rem;
}
.options-left-top {
  right: 100%;
  bottom: 0rem;
}
.max-h-220 {
  max-height: 220px;
}

::v-deep .cell-min-width {
  min-width: 256px;
}
:deep(.v-popover) {
  @apply w-full justify-center;
}

button:disabled {
  cursor: not-allowed;
}

::v-deep table.sun-table tbody td,
::v-deep table.sun-table tfoot td {
  @apply border-r-2 border-white !important;
}

::v-deep table.sun-table thead th,
::v-deep table.sun-table tfoot tr td {
  @apply bg-gray-400 border-t-2 border-white !important;
}
::v-deep .sun-table-container table.sun-table.hoverable tbody tr:hover td {
  @apply bg-gray-300 !important;
}

.filter-select {
  width: 330px;
}

.filter-select.force-height {
  height: 500px;
}

::v-deep .multiselect--active {
  height: 400px;
  max-height: 400px;
}

::v-deep .multiselect__tags {
  height: 50px;
}
</style>
