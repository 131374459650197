var render = function render(_c, _vm) {
  return _c(
    "sun-data-table-row",
    [
      _c("sun-data-table-cell", { attrs: { "detail-cell": "", sticky: "" } }),
      _c("sun-data-table-cell", { attrs: { sticky: "" } }, [
        _c(
          "div",
          { staticClass: "text-center w-full" },
          [
            _c(
              "sun-button",
              {
                staticClass: "shadow-none",
                class: {
                  "hover:bg-gray-300": !_vm.props.loading,
                  "cursor-not-allowed": _vm.props.loading || _vm.props.disabled,
                },
                attrs: {
                  color: "white",
                  loading: _vm.props.loading,
                  disabled: _vm.props.disabled,
                },
                on: { click: _vm.listeners.click },
              },
              [_vm._v("Load more ")]
            ),
          ],
          1
        ),
      ]),
      _c("sun-data-table-cell", { attrs: { colspan: _vm.props.cols - 1 } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }