import { updateSettings as updateSharedSettings } from '@/services/shared/updateSettings';
import { api } from '.';

/**
 *
 * @param {Object} settings Object containing user config
 */
export function updateSettings(settings) {
  return updateSharedSettings(api, settings.toObject());
}
