var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "reason-blocked" } }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "flex flex-col reason-wrapper" },
          [
            _c("basic-loader", { staticClass: "flex h-6 m-2" }),
            _c("basic-loader", { staticClass: "flex h-20 mx-2 mb-1" }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "flex",
            class: [
              _vm.doubleDonut ? "double-reason-wrapper" : "reason-wrapper",
            ],
          },
          [
            _vm.donutSeries.length
              ? _c("wrapper-donut-chart", {
                  class: _vm.doubleDonut ? "w-1/2" : "w-full",
                  attrs: {
                    title: _vm.title,
                    options: _vm.donutOptions,
                    series: _vm.donutSeries,
                    legend: _vm.donutLegend,
                  },
                })
              : _vm._e(),
            _vm.donutSeriesSecond.length
              ? _c("wrapper-donut-chart", {
                  class: _vm.doubleDonut ? "w-1/2 border-l ml-2" : "w-full",
                  attrs: {
                    title: _vm.titleSecond,
                    options: _vm.donutOptionsSecond,
                    series: _vm.donutSeriesSecond,
                    legend: _vm.donutLegendSecond,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }