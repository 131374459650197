<template>
  <div class="text-sm text-gray-700 truncate flex flex-col text-center">
    <span v-if="onlyValid" class="text-green-600 mx-1">
      {{ format(value.valid) }}
    </span>
    <span v-else-if="onlyBlocked" class="text-red-600 mx-1">
      {{ format(value.blocked) }}
    </span>
    <span v-else-if="onlyAccumulated" :class="`text-${colorAccumulated}-600 mx-1`">
      {{ format(value.accumulated) }}
    </span>
    <span v-else class="text-gray-600 mx-1">
      <slot name="accumulated" :value="value">
        {{ format(value.accumulated) }}
      </slot>
    </span>

    <div
      v-if="!onlyBlocked && !onlyValid && !onlyAccumulated"
      class="text-xs flex flex-row align-center justify-center"
    >
      <span class="text-green-600 mx-1">
        {{ format(value.valid) }}
      </span>
      -
      <span class="text-red-600 mx-1">
        {{ format(value.blocked) }}
      </span>
    </div>
  </div>
</template>

<script>
import ValidAndInvalidObject from '@/model/modules/ecommerce/analytics/ValidAndInvalidObject.js';

export default {
  name: 'EcommerceAnalyticsValidInvalidCell',
  props: {
    value: { type: [ValidAndInvalidObject, Object], default: null },
    filterName: { type: String, default: '' },
    onlyValid: { type: Boolean, default: false },
    onlyBlocked: { type: Boolean, default: false },
    onlyAccumulated: { type: Boolean, default: false },
    colorAccumulated: { type: String, default: 'black' },
  },
  methods: {
    format(value) {
      const filter = this.$options.filters?.[this.filterName];
      if (typeof filter === 'function') {
        return filter(value);
      }
      return value || 0;
    },
  },
};
</script>
<style scoped></style>
