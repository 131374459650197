<template functional>
  <sun-data-table-row>
    <sun-data-table-cell detail-cell sticky></sun-data-table-cell>
    <sun-data-table-cell sticky>
      <div class="text-center w-full">
        <sun-button
          color="white"
          class="shadow-none"
          :class="{ 'hover:bg-gray-300': !props.loading, 'cursor-not-allowed': props.loading || props.disabled }"
          :loading="props.loading"
          :disabled="props.disabled"
          @click="listeners.click"
          >Load more
        </sun-button>
      </div>
    </sun-data-table-cell>
    <sun-data-table-cell :colspan="props.cols - 1"></sun-data-table-cell>
  </sun-data-table-row>
</template>

<script>
export default {
  name: 'AsterixTableRowLoadMore',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
::v-deep .sun-loading {
  @apply text-gray-400;
}
</style>
