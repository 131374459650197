<template>
  <div class="w-full flex">
    <apexchart type="donut" :options="options" :series="series"></apexchart>
  </div>
</template>
<script>
export default {
  name: 'AsterixDonutChart',
  props: {
    options: {
      type: Object,
      default: () => {},
      required: true,
    },
    series: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
};
</script>
