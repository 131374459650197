var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-sm text-gray-700 truncate flex flex-col text-center" },
    [
      _vm.onlyValid
        ? _c("span", { staticClass: "text-green-600 mx-1" }, [
            _vm._v(" " + _vm._s(_vm.format(_vm.value.valid)) + " "),
          ])
        : _vm.onlyBlocked
        ? _c("span", { staticClass: "text-red-600 mx-1" }, [
            _vm._v(" " + _vm._s(_vm.format(_vm.value.blocked)) + " "),
          ])
        : _vm.onlyAccumulated
        ? _c("span", { class: `text-${_vm.colorAccumulated}-600 mx-1` }, [
            _vm._v(" " + _vm._s(_vm.format(_vm.value.accumulated)) + " "),
          ])
        : _c(
            "span",
            { staticClass: "text-gray-600 mx-1" },
            [
              _vm._t(
                "accumulated",
                function () {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.format(_vm.value.accumulated)) + " "
                    ),
                  ]
                },
                { value: _vm.value }
              ),
            ],
            2
          ),
      !_vm.onlyBlocked && !_vm.onlyValid && !_vm.onlyAccumulated
        ? _c(
            "div",
            {
              staticClass: "text-xs flex flex-row align-center justify-center",
            },
            [
              _c("span", { staticClass: "text-green-600 mx-1" }, [
                _vm._v(" " + _vm._s(_vm.format(_vm.value.valid)) + " "),
              ]),
              _vm._v(" - "),
              _c("span", { staticClass: "text-red-600 mx-1" }, [
                _vm._v(" " + _vm._s(_vm.format(_vm.value.blocked)) + " "),
              ]),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }