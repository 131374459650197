class ReportParamsBuilder {
  constructor(format = '', event = [], date = { start: null, end: null }, filter = {}, columns = []) {
    this.format = format;
    this.event = event;
    this.date = date;
    this.filter = filter;
    this.columns = columns;
  }

  addFilter(key, value) {
    this.filter[key] = value;
    return this;
  }

  hasFilter(key) {
    return !!this.filter[key];
  }
}
export default ReportParamsBuilder;
