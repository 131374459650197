var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus },
    scopedSlots: _vm._u([
      {
        key: "header-right",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("sun-date-picker", {
                  key: _vm.datepickerKey,
                  staticClass: "w-auto text-sm font-light text-gray-800",
                  attrs: {
                    id: "filter-date",
                    name: "filterDates",
                    value: _vm.quickFiltersDate,
                    separator: "to",
                    "cancel-error": "",
                    "show-date-list": "",
                    "date-list": _vm.dateList,
                    required: "",
                    "single-date-picker": false,
                    "limit-min-date": _vm.datePickerMin,
                    "limit-max-date": _vm.datePickerMax,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onQuickFiltersDate($event.value)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm.onSubqueryItem
              ? _c("dismissible-background", {
                  on: {
                    close: function ($event) {
                      _vm.onSubqueryItem = null
                    },
                  },
                })
              : _vm._e(),
            _vm.showChart
              ? _c(
                  "div",
                  { staticClass: "w-full flex pb-8" },
                  [
                    _c("analytics-chart", {
                      attrs: {
                        loading: !_vm.isLoadingChart,
                        "chart-series": _vm.chartSeries,
                        "chart-options": _vm.chartOptions,
                        "date-start": _vm.quickFiltersDate.startDate,
                        "date-end": _vm.quickFiltersDate.endDate,
                        "data-type": _vm.chartType,
                      },
                      on: {
                        change: function ($event) {
                          _vm.chartTypeSelected = $event.id
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.isChartCurrencyValue
                            ? {
                                key: "total",
                                fn: function ({ total }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm._f("currency")(total)) +
                                        " "
                                    ),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("sun-filter-layout", {
              staticClass: "mb-5",
              attrs: {
                id: "filter-box",
                "filters-added": _vm.filterFind,
                "filters-available": _vm.availableFilters,
                "has-error": _vm.anyError,
                "show-reset": "",
                "show-reset-button": "",
                "hide-back-button": "",
              },
              on: {
                close: _vm.filtersResetErrors,
                remove: _vm.filtersResetErrors,
                reset: _vm.resetFilters,
                "reset-selection": _vm.resetSelection,
                change: _vm.addFilters,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "quickFilters",
                    fn: function () {
                      return [
                        _c("div", { staticClass: "flex flex-wrap min-w-max" }, [
                          _c(
                            "div",
                            { staticClass: "m-1" },
                            [
                              _c("sun-search-input", {
                                attrs: {
                                  value: _vm.filterQuick["breakdown"],
                                  "class-input": "text-sm",
                                  disabled: _vm.isQuickSearchDisabled,
                                },
                                on: {
                                  search: function ($event) {
                                    return _vm.onQuickFiltersSearch(
                                      $event,
                                      "breakdown"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: `filter.countryIso`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("sun-select", {
                          staticClass: "filter-select",
                          attrs: {
                            id: `filter_${filter.name}`,
                            "class-input": "pt-0 rounded-none multiselect",
                            "add-hex-color": "orange",
                            label: "name",
                            multiple: "",
                            "max-height": 350,
                            options: _vm.countries,
                            "close-on-select": false,
                            "track-by": filter.trackBy,
                            reseted: _vm.resetedSeveralValueFilters,
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event,
                                onSelect
                              )
                            },
                            blur: _vm.closeSelect,
                            focus: _vm.forceSelectHeight,
                            reseted: function ($event) {
                              _vm.resetedSeveralValueFilters = false
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.publisherId`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "filter-select",
                          attrs: {
                            id: `filter_${filter.name}`,
                            name: "publisher-select-filter",
                            service: filter.service,
                            "track-by": filter.trackBy,
                            "max-height": 350,
                            "color-tag": "green",
                            label: "name",
                            "close-on-select": false,
                            multiple: "",
                            reseted: _vm.resetedSeveralValueFilters,
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event,
                                onSelect
                              )
                            },
                            focus: _vm.forceSelectHeight,
                            blur: _vm.closeSelect,
                            reseted: function ($event) {
                              _vm.resetedSeveralValueFilters = false
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.offerId`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "filter-select",
                          attrs: {
                            id: `filter_${filter.name}`,
                            name: "select-offer",
                            "track-by": filter.trackBy,
                            "max-height": 350,
                            "color-tag": "green",
                            label: "name",
                            "add-hex-color": "orange",
                            "close-on-select": false,
                            multiple: "",
                            reseted: _vm.resetedSeveralValueFilters,
                            service: filter.service,
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event,
                                onSelect
                              )
                            },
                            focus: _vm.forceSelectHeight,
                            blur: _vm.closeSelect,
                            reseted: function ($event) {
                              _vm.resetedSeveralValueFilters = false
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.advertiserId`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "filter-select",
                          attrs: {
                            id: `filter_${filter.name}`,
                            name: "select-advertiser",
                            "track-by": filter.trackBy,
                            "max-height": 350,
                            "color-tag": "green",
                            label: "name",
                            "add-hex-color": "orange",
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            "close-on-select": false,
                            multiple: "",
                            reseted: _vm.resetedSeveralValueFilters,
                            "items-per-page": 100,
                            service: filter.service,
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event,
                                onSelect
                              )
                            },
                            focus: _vm.forceSelectHeight,
                            blur: _vm.closeSelect,
                            reseted: function ($event) {
                              _vm.resetedSeveralValueFilters = false
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.campaignId`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "filter-select",
                          attrs: {
                            id: `filter_${filter.name}`,
                            name: "select-campaign",
                            "track-by": filter.trackBy,
                            "max-height": 350,
                            "color-tag": "green",
                            "add-hex-color": "orange",
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            "close-on-select": false,
                            multiple: "",
                            reseted: _vm.resetedSeveralValueFilters,
                            service: filter.service,
                            "items-per-page": 100,
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event,
                                onSelect
                              )
                            },
                            focus: _vm.forceSelectHeight,
                            blur: _vm.closeSelect,
                            reseted: function ($event) {
                              _vm.resetedSeveralValueFilters = false
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.subPublisherCategory`,
                    fn: function ({ filter, onSelect, isDuplicate }) {
                      return [
                        _c("sun-input", {
                          attrs: {
                            type: "text",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                            enter: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col items-end justify-end w-full p-2 rounded-t-md bg-white lg:flex-row lg:items-center",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row items-center mb-2 lg:pr-1 lg:mb-0",
                  },
                  [
                    _c("columns-selector-ecommerce", {
                      attrs: { "default-columns": _vm.columnsSelectorOptions },
                      on: { change: _vm.updateVisibleHeaders },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "h-10 mb-2 breakdown lg:mb-0 flex items-center",
                  },
                  [
                    _c("breakdown-wrapper", {
                      attrs: {
                        label: "Select Breakdowns",
                        items: _vm.breakdownOptions,
                        disabled: _vm.breakdownDisabled,
                        "horizontal-grow": "",
                        "hide-secondary": "",
                      },
                      on: { reset: _vm.resetBreakdown },
                      model: {
                        value: _vm.breakdown,
                        callback: function ($$v) {
                          _vm.breakdown = $$v
                        },
                        expression: "breakdown",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-row items-center mb-2 ml-1 lg:mb-0",
                  },
                  [
                    !_vm.logButtonAvailable && _vm.showLogButton
                      ? _c(
                          "sun-popover",
                          {
                            attrs: {
                              "text-info": _vm.popOverLogButton,
                              position: "top",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "px-4 py-2 bg-white rounded opacity-25",
                                attrs: { disabled: "" },
                              },
                              [_c("log-file-svg", { staticClass: "w-5 mr-1" })],
                              1
                            ),
                          ]
                        )
                      : _vm.showLogButton
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "px-4 py-2 bg-white hover:bg-gray-300 rounded",
                            attrs: {
                              disabled:
                                _vm.isLoading ||
                                _vm.reportToastLoading ||
                                !_vm.logButtonAvailable,
                            },
                            on: {
                              click: function ($event) {
                                _vm.showLogReportModal = true
                              },
                            },
                          },
                          [_c("log-file-svg", { staticClass: "w-5 mr-1" })],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex flex-row items-center mb-2 ml-1 lg:mb-0",
                  },
                  [
                    _c(
                      "asterix-dropdown",
                      {
                        attrs: {
                          "button-classes":
                            "flex hover:bg-gray-300 focus:bg-gray-800 text-gray-700 focus:text-gray-200 rounded h-10 px-4 items-center",
                          disabled: _vm.isLoading || _vm.reportToastLoading,
                          options: _vm.reportOptions,
                        },
                        on: { change: _vm.handlerReport },
                        scopedSlots: _vm._u([
                          {
                            key: "dropdown-header",
                            fn: function () {
                              return [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "p-2 pt-0 text-sm font-bold text-left whitespace-no-wrap",
                                  },
                                  [_vm._v("Download as")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "icon",
                            fn: function (state) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "transition-100",
                                    class: !state.visible ? "rotate-180" : "",
                                  },
                                  [
                                    _c("up-svg", {
                                      staticClass: "w-2 h-2",
                                      class: state.disabled
                                        ? "text-gray-400"
                                        : "text-gray-500",
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "block w-5 mr-2" },
                          [_c("csv-file-svg")],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "flex flex-col w-full mb-8" },
              [
                _c("sun-data-table", {
                  attrs: {
                    content: _vm.items,
                    detailed: !!_vm.breakdown.secondary,
                    "expand-all-detail-button": "",
                    headers: _vm.availableHeadersWithoutPostEvent,
                    hoverable: "",
                    striped: "",
                    loading: _vm.isLoading,
                  },
                  on: {
                    expandAllDetail: _vm.expandAllDetail,
                    expandDetail: _vm.expandDetail,
                    sort: _vm.wrapperOnSortTable,
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.tablePostEventColumns, function (column) {
                        return {
                          key: `col.${column.name}`,
                          fn: function ({ item, columnClass }) {
                            return [
                              _c(
                                "sun-data-table-cell",
                                { key: column.name, class: columnClass },
                                [
                                  item.postEvents &&
                                  item.postEvents[column.property]
                                    ? _c(
                                        "ecommerce-analytics-valid-invalid-cell",
                                        {
                                          attrs: {
                                            value:
                                              item.postEvents[column.property],
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "accumulated",
                                                fn: function ({ value }) {
                                                  return [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(value.total) +
                                                        " "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        }
                                      )
                                    : _c("span", [
                                        _c(
                                          "span",
                                          { staticClass: "text-green-600" },
                                          [_vm._v("0")]
                                        ),
                                        _c("span", { staticClass: "mx-2" }, [
                                          _vm._v("-"),
                                        ]),
                                        _c(
                                          "span",
                                          { staticClass: "text-red-600" },
                                          [_vm._v("0")]
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          },
                        }
                      }),
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                ref: `colActions${item.id}`,
                                class: columnClass,
                              },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                    dotsClicked: function ($event) {
                                      _vm.subQueryMenuActive = item
                                    },
                                  },
                                }),
                                item === _vm.subQueryMenuActive
                                  ? _c(
                                      "teleport",
                                      {
                                        attrs: {
                                          visible: true,
                                          position: _vm.actionCellPosition,
                                        },
                                      },
                                      [
                                        _vm.showSubqueryMenu(item)
                                          ? [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex rounded-lg shadow-md transition-100 flex-col absolute z-100 bg-gray-800 p-3 text-gray-300 min-w-full whitespace-no-wrap overflow-y-scroll max-h-220",
                                                  class:
                                                    _vm.subQueryMenuClasses,
                                                },
                                                _vm._l(
                                                  _vm.breakdownOptions.items,
                                                  function (option) {
                                                    return _c(
                                                      "span",
                                                      {
                                                        key: option.name,
                                                        staticClass:
                                                          "text-left cursor-pointer w-full px-3 text-xs font-bold text-gray-500 hover:text-white hover:bg-gray-700 rounded-full",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onPrimarySubquery(
                                                              option.value
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              option.name
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.breakdown`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                class: columnClass,
                                attrs: {
                                  sticky: "",
                                  "detail-cell": !_vm.breakdown.secondary,
                                  width: "300",
                                },
                              },
                              [
                                _c("ecommerce-breakdown-display", {
                                  attrs: {
                                    value: item,
                                    breakdown: _vm.breakdown.primary,
                                    "can-edit": _vm.roleCanEdit,
                                  },
                                }),
                                _vm.isOfferBreakdown &&
                                !item.campaignIsAutogenerated
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "text-xs font-bold text-gray-600 truncate",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.campaignName) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.publisherName`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _c(
                                "p",
                                {
                                  staticClass: "text-xs text-gray-600 truncate",
                                },
                                [_vm._v(_vm._s(item.publisherName))]
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.clicksAccumulated`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                item.clicks.blocked > 0
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.validInvalidClick(
                                              item,
                                              _vm.blockedTypes.CLICK,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "ecommerce-analytics-valid-invalid-cell",
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: { value: item.clicks },
                                          }
                                        ),
                                        item.clkReason
                                          ? _c("dismissible-background", {
                                              on: {
                                                close: function ($event) {
                                                  return _vm.closeInvalidReason(
                                                    item,
                                                    _vm.blockedTypes.CLICK
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "ecommerce-analytics-valid-invalid-cell",
                                      { attrs: { value: item.clicks } }
                                    ),
                                item.clkReason
                                  ? _c(
                                      "teleport",
                                      {
                                        attrs: {
                                          visible: true,
                                          position: _vm.reasonCellPosition,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bg-white flex rounded-lg shadow-md transition-100 flex-col absolute z-100 text-xs",
                                            class: _vm.reasonDisplayClasses,
                                          },
                                          [
                                            _c("analytics-blocked-reasons", {
                                              attrs: {
                                                "filters-applied":
                                                  _vm.filterFind,
                                                entity: item,
                                                "event-type":
                                                  _vm.blockedTypes.CLICK,
                                                breakdown:
                                                  _vm.breakdown.primary,
                                                "date-range":
                                                  _vm.quickFiltersDate,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.clicks`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.clicks,
                                    "only-valid": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.clicksBlocked`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.clicks,
                                    "only-blocked": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.conversionsAccumulated`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                item.conversions.blocked > 0
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.validInvalidClick(
                                              item,
                                              _vm.blockedTypes.CONVERSION,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "ecommerce-analytics-valid-invalid-cell",
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              value: item.conversions,
                                              "only-accumulated":
                                                _vm.isAdvertiserUser,
                                              "color-accumulated": "green",
                                            },
                                          }
                                        ),
                                        item.conv_pevReason
                                          ? _c("dismissible-background", {
                                              on: {
                                                close: function ($event) {
                                                  return _vm.closeInvalidReason(
                                                    item,
                                                    _vm.blockedTypes.CONVERSION
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "ecommerce-analytics-valid-invalid-cell",
                                      {
                                        attrs: {
                                          value: item.conversions,
                                          "only-accumulated":
                                            _vm.isAdvertiserUser,
                                          "color-accumulated": "green",
                                        },
                                      }
                                    ),
                                item.conv_pevReason
                                  ? _c(
                                      "teleport",
                                      {
                                        attrs: {
                                          visible: true,
                                          position: _vm.reasonCellPosition,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "bg-white flex rounded-lg shadow-md transition-100 flex-col absolute z-100 text-xs",
                                            class: _vm.reasonDisplayClasses,
                                          },
                                          [
                                            _c("analytics-blocked-reasons", {
                                              attrs: {
                                                "filters-applied":
                                                  _vm.filterFind,
                                                entity: item,
                                                "event-type":
                                                  _vm.blockedTypes.CONVERSION,
                                                breakdown:
                                                  _vm.breakdown.primary,
                                                "date-range":
                                                  _vm.quickFiltersDate,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.conversions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.conversions,
                                    "only-valid": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.conversionsBlocked`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.conversions,
                                    "only-blocked": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.impressionsAccumulated`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                item.impressions.blocked > 0
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.validInvalidClick(
                                              item,
                                              _vm.blockedTypes.IMPRESSION,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "ecommerce-analytics-valid-invalid-cell",
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: { value: item.impressions },
                                          }
                                        ),
                                        item.impReason
                                          ? _c("dismissible-background", {
                                              on: {
                                                close: function ($event) {
                                                  return _vm.closeInvalidReason(
                                                    item,
                                                    _vm.blockedTypes.IMPRESSION
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "ecommerce-analytics-valid-invalid-cell",
                                      { attrs: { value: item.impressions } }
                                    ),
                                item.impReason
                                  ? _c(
                                      "teleport",
                                      {
                                        attrs: {
                                          visible: true,
                                          position: _vm.reasonCellPosition,
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "bg-white flex rounded-lg shadow-md transition-100 flex-col absolute z-100 text-xs",
                                              class: _vm.reasonDisplayClasses,
                                            },
                                            [
                                              _c("analytics-blocked-reasons", {
                                                attrs: {
                                                  "filters-applied":
                                                    _vm.filterFind,
                                                  entity: item,
                                                  "event-type":
                                                    _vm.blockedTypes.IMPRESSION,
                                                  breakdown:
                                                    _vm.breakdown.primary,
                                                  "date-range":
                                                    _vm.quickFiltersDate,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.impressions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.impressions,
                                    "only-valid": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.impressionsBlocked`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.impressions,
                                    "only-blocked": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.leadsAccumulated`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                item.leads.blocked > 0
                                  ? _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.validInvalidClick(
                                              item,
                                              _vm.blockedTypes.LEADS,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "ecommerce-analytics-valid-invalid-cell",
                                          {
                                            staticClass: "cursor-pointer",
                                            attrs: {
                                              value: item.leads,
                                              "only-accumulated":
                                                _vm.isAdvertiserUser,
                                              "color-accumulated": "green",
                                            },
                                          }
                                        ),
                                        item.convReason
                                          ? _c("dismissible-background", {
                                              on: {
                                                close: function ($event) {
                                                  return _vm.closeInvalidReason(
                                                    item,
                                                    _vm.blockedTypes.LEADS
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "ecommerce-analytics-valid-invalid-cell",
                                      {
                                        attrs: {
                                          value: item.leads,
                                          "only-accumulated":
                                            _vm.isAdvertiserUser,
                                          "color-accumulated": "green",
                                        },
                                      }
                                    ),
                                item.convReason
                                  ? _c(
                                      "teleport",
                                      {
                                        attrs: {
                                          visible: true,
                                          position: _vm.reasonCellPosition,
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "bg-white flex rounded-lg shadow-md transition-100 flex-col absolute z-100 text-xs",
                                              class: _vm.reasonDisplayClasses,
                                            },
                                            [
                                              _c("analytics-blocked-reasons", {
                                                attrs: {
                                                  "filters-applied":
                                                    _vm.filterFind,
                                                  entity: item,
                                                  "event-type":
                                                    _vm.blockedTypes.LEADS,
                                                  breakdown:
                                                    _vm.breakdown.primary,
                                                  "date-range":
                                                    _vm.quickFiltersDate,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.leads`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.leads,
                                    "only-valid": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.leadsBlocked`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.leads,
                                    "only-blocked": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.orderValueAccumulated`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.orderValue,
                                    "filter-name": "currency",
                                    "only-accumulated": _vm.isAdvertiserUser,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.orderValue`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.orderValue,
                                    "filter-name": "currency",
                                    "only-valid": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.orderValueBlocked`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("ecommerce-analytics-valid-invalid-cell", {
                                  attrs: {
                                    value: item.orderValue,
                                    "filter-name": "currency",
                                    "only-blocked": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.revenue`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(_vm._s(_vm._f("currency")(item.revenue))),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.grossRevenue`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(
                                _vm._s(_vm._f("currency")(item.grossRevenue))
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.expense`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(_vm._s(_vm._f("currency")(item.expense))),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.profit`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(_vm._s(_vm._f("currency")(item.profit))),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.cr`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _c(
                                "div",
                                { staticClass: "flex flex-col items-center" },
                                [
                                  _c("span", { staticClass: "font-bold" }, [
                                    _vm._v("1 : " + _vm._s(item.crRate)),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm._f("percentage")(item.cr)) +
                                        ")"
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.url`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                staticClass: "text-center",
                                class: columnClass,
                              },
                              [_vm._v(" " + _vm._s(item.url || "-") + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.category`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                staticClass: "text-center",
                                class: columnClass,
                              },
                              [_vm._v(_vm._s(item.category || "-"))]
                            ),
                          ]
                        },
                      },
                      {
                        key: "detailed",
                        fn: function ({ item }) {
                          return [
                            _vm._l(item.children, function (detail, $iRow) {
                              return _c(
                                "sun-data-table-row",
                                { key: $iRow + "_" + item.id },
                                [
                                  _c("sun-data-table-cell", {
                                    attrs: { sticky: "", "detail-cell": "" },
                                  }),
                                  _vm._l(
                                    _vm.availableHeadersWithoutPostEvent,
                                    function (column, $index) {
                                      return [
                                        column && column.visible
                                          ? [
                                              _c(
                                                "sun-data-table-cell",
                                                {
                                                  key: `${$index}_${column}`,
                                                  class: [
                                                    `text-${column.align}`,
                                                  ],
                                                },
                                                [
                                                  column.value ===
                                                  _vm.COLUMNS.ACTIONS.value
                                                    ? _c("span")
                                                    : column.value ===
                                                      _vm.COLUMNS.BREAKDOWN
                                                        .value
                                                    ? [
                                                        _c(
                                                          "ecommerce-breakdown-display",
                                                          {
                                                            attrs: {
                                                              "can-edit":
                                                                _vm.roleCanEdit,
                                                              value: detail,
                                                              breakdown:
                                                                _vm.breakdown
                                                                  .secondary,
                                                              "is-sub-breakdown":
                                                                "",
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    : [
                                                        _vm.COLUMNS.IMPRESSIONS
                                                          .value,
                                                        _vm.COLUMNS.CLICKS
                                                          .value,
                                                        _vm.COLUMNS.CONVERSIONS
                                                          .value,
                                                        _vm.COLUMNS.LEADS.value,
                                                      ].includes(column.value)
                                                    ? [
                                                        _c(
                                                          "ecommerce-analytics-valid-invalid-cell",
                                                          {
                                                            attrs: {
                                                              value:
                                                                detail[
                                                                  _vm
                                                                    .COLUMNS_VALID_BLOCKED[
                                                                    column.value
                                                                  ]
                                                                ],
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    : [
                                                        _vm.COLUMNS
                                                          .IMPRESSIONS_VALID
                                                          .value,
                                                        _vm.COLUMNS.CLICKS_VALID
                                                          .value,
                                                        _vm.COLUMNS
                                                          .CONVERSIONS_VALID
                                                          .value,
                                                        _vm.COLUMNS.LEADS_VALID
                                                          .value,
                                                      ].includes(column.value)
                                                    ? [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-green-600",
                                                          },
                                                          [
                                                            _c(
                                                              "ecommerce-analytics-valid-invalid-cell",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    detail[
                                                                      _vm
                                                                        .COLUMNS_VALID_BLOCKED[
                                                                        column
                                                                          .value
                                                                      ]
                                                                    ],
                                                                  "only-valid":
                                                                    "",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : [
                                                        _vm.COLUMNS
                                                          .IMPRESSIONS_BLOCKED
                                                          .value,
                                                        _vm.COLUMNS
                                                          .CLICKS_BLOCKED.value,
                                                        _vm.COLUMNS
                                                          .CONVERSIONS_BLOCKED
                                                          .value,
                                                        _vm.COLUMNS
                                                          .LEADS_BLOCKED.value,
                                                      ].includes(column.value)
                                                    ? [
                                                        _c(
                                                          "ecommerce-analytics-valid-invalid-cell",
                                                          {
                                                            attrs: {
                                                              value:
                                                                detail[
                                                                  _vm
                                                                    .COLUMNS_VALID_BLOCKED[
                                                                    column.value
                                                                  ]
                                                                ],
                                                              "only-blocked":
                                                                "",
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    : _vm.COLUMNS.ORDER_VALUE
                                                        .value === column.value
                                                    ? [
                                                        _c(
                                                          "ecommerce-analytics-valid-invalid-cell",
                                                          {
                                                            attrs: {
                                                              value:
                                                                detail[
                                                                  _vm
                                                                    .COLUMNS_VALID_BLOCKED[
                                                                    column.value
                                                                  ]
                                                                ],
                                                              "filter-name":
                                                                "currency",
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    : _vm.COLUMNS
                                                        .ORDER_VALUE_VALID
                                                        .value === column.value
                                                    ? [
                                                        _c(
                                                          "ecommerce-analytics-valid-invalid-cell",
                                                          {
                                                            attrs: {
                                                              value:
                                                                detail[
                                                                  _vm
                                                                    .COLUMNS_VALID_BLOCKED[
                                                                    column.value
                                                                  ]
                                                                ],
                                                              "filter-name":
                                                                "currency",
                                                              "only-valid": "",
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    : _vm.COLUMNS
                                                        .ORDER_VALUE_BLOCKED
                                                        .value === column.value
                                                    ? [
                                                        _c(
                                                          "ecommerce-analytics-valid-invalid-cell",
                                                          {
                                                            attrs: {
                                                              value:
                                                                detail[
                                                                  _vm
                                                                    .COLUMNS_VALID_BLOCKED[
                                                                    column.value
                                                                  ]
                                                                ],
                                                              "filter-name":
                                                                "currency",
                                                              "only-blocked":
                                                                "",
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    : [
                                                        _vm.COLUMNS.REVENUE
                                                          .value,
                                                        _vm.COLUMNS.EXPENSE
                                                          .value,
                                                        _vm.COLUMNS.PROFIT
                                                          .value,
                                                      ].includes(column.value)
                                                    ? [
                                                        _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  detail[
                                                                    column.value
                                                                  ]
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]),
                                                      ]
                                                    : _vm.COLUMNS.CR.value ===
                                                      column.value
                                                    ? [
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(
                                                              detail[
                                                                _vm.COLUMNS.CR
                                                                  .value
                                                              ].toFixed(2)
                                                            ) + "%"
                                                          ),
                                                        ]),
                                                      ]
                                                    : _vm.hasPostEventsDetail(
                                                        column
                                                      )
                                                    ? [
                                                        detail.postEvents
                                                          ? _c(
                                                              "ecommerce-analytics-valid-invalid-cell",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm.getPostEventDetails(
                                                                      column,
                                                                      detail
                                                                    ),
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "accumulated",
                                                                        fn: function ({
                                                                          value,
                                                                        }) {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  value.total
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    : _vm.COLUMNS.GROSS_REVENUE
                                                        .value === column.value
                                                    ? [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                detail[
                                                                  _vm.COLUMNS
                                                                    .GROSS_REVENUE
                                                                    .value
                                                                ]
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    : _c("p", [
                                                        _vm._v(
                                                          "- " +
                                                            _vm._s(
                                                              _vm.tablePostEventColumns
                                                            )
                                                        ),
                                                      ]),
                                                ],
                                                2
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            }),
                            _vm.canGetMoreItems(item) &&
                            !_vm.detailPagination.getError(item.id)
                              ? _c("asterix-table-row-load-more", {
                                  attrs: {
                                    loading: _vm.detailPagination.isLoading(
                                      item.id
                                    ),
                                    cols: _vm.availableHeaders.length,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleNextDetail(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                            !item.children.length
                              ? _c(
                                  "sun-data-table-row",
                                  [
                                    _c("sun-data-table-cell", {
                                      staticClass: "bg-gray-300",
                                      attrs: { "detail-cell": "", sticky: "" },
                                    }),
                                    _c(
                                      "sun-data-table-cell",
                                      {
                                        staticClass:
                                          "text-left bg-gray-300 italic text-gray-700",
                                        attrs: { colspan: _vm.headers.length },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "ml-16 text-left" },
                                          [
                                            _vm._v(
                                              "No data found for this option"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                      _vm.totals
                        ? {
                            key: "footer",
                            fn: function () {
                              return [
                                _vm._l(
                                  _vm.availableHeadersWithoutPostEvent,
                                  function (column) {
                                    return [
                                      column.value ===
                                      _vm.COLUMNS.BREAKDOWN.value
                                        ? [
                                            _c(
                                              "sun-data-table-cell",
                                              {
                                                key: `footer_${column.name}_totals`,
                                                staticClass:
                                                  "w-full border-t-2 bg-gray-400",
                                                staticStyle: {
                                                  left: "0 !important",
                                                  "border-right":
                                                    "0 !important",
                                                },
                                                attrs: { sticky: "" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "font-bold uppercase pl-2",
                                                    attrs: {
                                                      "data-testid":
                                                        "name-total-cell",
                                                    },
                                                  },
                                                  [_vm._v("Total")]
                                                ),
                                              ]
                                            ),
                                            _vm.columnsByBreakdown.length > 0
                                              ? _c("sun-data-table-cell", {
                                                  key: `footer_${column.name}_totals_1`,
                                                  staticClass: "w-full",
                                                })
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      column.value ===
                                        _vm.COLUMNS.IMPRESSIONS.value &&
                                      column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_impressions`,
                                            },
                                            [
                                              _c(
                                                "ecommerce-analytics-valid-invalid-cell",
                                                {
                                                  attrs: {
                                                    value: {
                                                      valid:
                                                        _vm.totals.impressions,
                                                      blocked:
                                                        _vm.totals
                                                          .impressionsBlocked,
                                                      accumulated:
                                                        _vm.totals
                                                          .impressionsAccumulated,
                                                    },
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.IMPRESSIONS_VALID
                                              .value && column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_impressionValid`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totals.impressions
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.IMPRESSIONS_BLOCKED
                                              .value && column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_impressionsBlocked`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totals
                                                      .impressionsBlocked
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.CLICKS.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_clicksAccumulated`,
                                            },
                                            [
                                              _c(
                                                "ecommerce-analytics-valid-invalid-cell",
                                                {
                                                  attrs: {
                                                    value: {
                                                      valid: _vm.totals.clicks,
                                                      blocked:
                                                        _vm.totals
                                                          .clicksBlocked,
                                                      accumulated:
                                                        _vm.totals
                                                          .clicksAccumulated,
                                                    },
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.CLICKS_VALID.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_clicks`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.totals.clicks) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.CLICKS_BLOCKED.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_clicksBlocked`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totals.clicksBlocked
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.CONVERSIONS.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_conversionsAccumulated`,
                                            },
                                            [
                                              _c(
                                                "ecommerce-analytics-valid-invalid-cell",
                                                {
                                                  attrs: {
                                                    value: {
                                                      valid:
                                                        _vm.totals.conversions,
                                                      blocked:
                                                        _vm.totals
                                                          .conversionsBlocked,
                                                      accumulated:
                                                        _vm.totals
                                                          .conversionsAccumulated,
                                                    },
                                                    "only-accumulated":
                                                      _vm.isAdvertiserUser,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.CONVERSIONS_VALID
                                              .value && column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_conversions`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totals.conversions
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.CONVERSIONS_BLOCKED
                                              .value && column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_conversionsBlocked`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totals
                                                      .conversionsBlocked
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.LEADS.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_leadsAccumulated`,
                                            },
                                            [
                                              _c(
                                                "ecommerce-analytics-valid-invalid-cell",
                                                {
                                                  attrs: {
                                                    value: {
                                                      valid: _vm.totals.leads,
                                                      blocked:
                                                        _vm.totals.leadsBlocked,
                                                      accumulated:
                                                        _vm.totals
                                                          .leadsAccumulated,
                                                    },
                                                    "only-accumulated":
                                                      _vm.isAdvertiserUser,
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.LEADS_VALID.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_leads`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.totals.leads) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.LEADS_BLOCKED.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_leadsBlocked`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totals.leadsBlocked
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.ORDER_VALUE.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_order_value_accumulated`,
                                            },
                                            [
                                              _c(
                                                "ecommerce-analytics-valid-invalid-cell",
                                                {
                                                  attrs: {
                                                    value: {
                                                      valid:
                                                        _vm.totals.orderValue,
                                                      blocked:
                                                        _vm.totals
                                                          .orderValueBlocked,
                                                      accumulated:
                                                        _vm.totals
                                                          .orderValueAccumulated,
                                                    },
                                                    "only-accumulated":
                                                      _vm.isAdvertiserUser,
                                                    "filter-name": "currency",
                                                  },
                                                }
                                              ),
                                            ],
                                            1
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.ORDER_VALUE_VALID
                                              .value && column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_order_value`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totals.orderValue
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.ORDER_VALUE_BLOCKED
                                              .value && column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_order_value_blocked`,
                                              staticClass: "text-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.totals.orderValueBlocked
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.REVENUE.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_revenue`,
                                              staticClass: "text-right pr-4",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.totals.revenue
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.GROSS_REVENUE.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_gross_revenue`,
                                              staticClass: "text-right pr-4",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.totals.grossRevenue
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.EXPENSE.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_expense`,
                                              staticClass: "text-right pr-4",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.totals.expense
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.PROFIT.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            {
                                              key: `footer_${column.name}_profit`,
                                              staticClass: "text-right pr-4",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("currency")(
                                                      _vm.totals.profit
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.CR.value &&
                                          column.visible
                                        ? _c(
                                            "sun-data-table-cell",
                                            { key: `footer_${column.name}_cr` },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex flex-col items-center",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "font-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "1 : " +
                                                          _vm._s(
                                                            _vm.totals.crRate
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("span", [
                                                    _vm._v(
                                                      "(" +
                                                        _vm._s(
                                                          _vm._f("percentage")(
                                                            _vm.totals.cr
                                                          )
                                                        ) +
                                                        ")"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : column.value ===
                                            _vm.COLUMNS.CATEGORY.value &&
                                          column.visible
                                        ? _c("sun-data-table-cell", {
                                            key: `footer_${column.name}_cr`,
                                            staticClass:
                                              "w-full border-t-2 bg-gray-400",
                                          })
                                        : column.value ===
                                            _vm.COLUMNS.URL.value &&
                                          column.visible
                                        ? _c("sun-data-table-cell", {
                                            key: `footer_${column.name}_cr`,
                                            staticClass:
                                              "w-full border-t-2 bg-gray-400",
                                          })
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                                _vm._l(
                                  _vm.tablePostEventColumns,
                                  function (column) {
                                    return _c(
                                      "sun-data-table-cell",
                                      {
                                        key: `footer_${column.name}_postEvent`,
                                      },
                                      [
                                        _vm.totals.postEvents &&
                                        _vm.totals.postEvents[column.property]
                                          ? _c(
                                              "ecommerce-analytics-valid-invalid-cell",
                                              {
                                                attrs: {
                                                  value:
                                                    _vm.totals.postEvents[
                                                      column.property
                                                    ],
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "accumulated",
                                                      fn: function ({ value }) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                value.total
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "px-4" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-green-600",
                                                  },
                                                  [_vm._v("0")]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "mx-2" },
                                                  [_vm._v("-")]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-red-600",
                                                  },
                                                  [_vm._v("0")]
                                                ),
                                              ]
                                            ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm.showActionsColumn
                                  ? _c("sun-data-table-cell", {
                                      staticClass:
                                        "w-full border-t-2 bg-gray-400",
                                    })
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
            _c("sun-pagination-page", {
              staticClass: "mb-8",
              attrs: {
                "total-pages": _vm.totalPages,
                "total-items": _vm.totalItems,
                "current-page": _vm.currentPage,
                value: _vm.itemsPerPage,
                "options-per-page": _vm.availablePageSizes,
              },
              on: {
                changePage: _vm.changePage,
                changePerPage: _vm.changeItemsPerPage,
              },
            }),
            _vm.showLogReportModal
              ? _c("report-log-modal", {
                  attrs: { "user-role": _vm.contextUserRole },
                  on: {
                    close: _vm.onCloseReportLogModal,
                    download: _vm.onDownloadLogReport,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }