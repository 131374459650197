var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col" }, [
    _c("div", { staticClass: "flex justify-center items-center h-6 m-2" }, [
      _vm._v(_vm._s(_vm.title)),
    ]),
    _vm.series.length
      ? _c(
          "div",
          { staticClass: "w-full" },
          [
            _c("asterix-donut-chart", {
              attrs: { options: _vm.options, series: _vm.series },
            }),
            _c(
              "div",
              { staticClass: "p-2 overflow-auto donut-legend" },
              _vm._l(_vm.legend, function (block, index) {
                return _c(
                  "div",
                  {
                    key: `block_${index}`,
                    staticClass: "flex-col border-b legend-item",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex pt-1 justify-between items-end" },
                      [
                        _c("div", { staticClass: "flex items-center" }, [
                          _c("span", {
                            staticClass: "w-4 h-2 rounded-full mr-1",
                            style: `background-color:${_vm.options.colors[index]};`,
                          }),
                          _c("span", { staticClass: "w-full" }, [
                            _vm._v(" " + _vm._s(block.label) + " "),
                          ]),
                        ]),
                        _c("span", [_vm._v(" " + _vm._s(block.value))]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        )
      : _c("div", { staticClass: "w-full" }, [_c("empty-search")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }