<template>
  <div id="reason-blocked">
    <div v-if="isLoading" class="flex flex-col reason-wrapper">
      <basic-loader class="flex h-6 m-2" />
      <basic-loader class="flex h-20 mx-2 mb-1" />
    </div>
    <div v-else class="flex" :class="[doubleDonut ? 'double-reason-wrapper' : 'reason-wrapper']">
      <wrapper-donut-chart
        v-if="donutSeries.length"
        :class="doubleDonut ? 'w-1/2' : 'w-full'"
        :title="title"
        :options="donutOptions"
        :series="donutSeries"
        :legend="donutLegend"
      />
      <wrapper-donut-chart
        v-if="donutSeriesSecond.length"
        :class="doubleDonut ? 'w-1/2 border-l ml-2' : 'w-full'"
        :title="titleSecond"
        :options="donutOptionsSecond"
        :series="donutSeriesSecond"
        :legend="donutLegendSecond"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import BasicLoader from '@/components/atoms/Loaders/BasicLoader';
import { getBlockedReasons } from '@/services/modules/ecommerce/analytics';
import { indexMixin } from '@/mixins/index/indexMixin';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import getDateISOwithUTC from '@/utils/dateTime/getDateISOwithUTC';
import { BREAKDOWN_VALUES } from '@/model/modules/ecommerce/analytics/BreakdownValues';
import { typeValues, donutChartOptions } from './config';
import WrapperDonutChart from '@/components/organisms/shared/asterixCharts/WrapperDonutChart.vue';

const TITLES_BY_TYPE = {
  [typeValues.IMPRESSIONS]: 'Impressions blocked',
  [typeValues.CLICKS]: 'Clicks blocked',
  [typeValues.CONVERSIONS]: 'Conversions blocked',
  [typeValues.POST_EVENTS]: 'Post Events blocked',
  [typeValues.LEADS]: 'Leads blocked',
};
const TYPE_FOR_BACK = {
  [typeValues.IMPRESSIONS]: 'impressions',
  [typeValues.CLICKS]: 'clicks',
  [typeValues.CONVERSIONS]: 'conversions',
  [typeValues.POST_EVENTS]: 'postEvents',
  [typeValues.LEADS]: 'conversions',
};

export default {
  name: 'AnalyticsBlockedReasons',
  components: {
    BasicLoader,
    WrapperDonutChart,
  },
  mixins: [indexMixin],
  props: {
    entity: {
      type: Object,
      default: () => null,
    },
    eventType: {
      type: String,
      default: () => null,
    },
    filtersApplied: {
      type: Array,
      default: () => [],
    },
    breakdown: {
      type: String,
      default: () => null,
    },
    isVisible: {
      type: Boolean,
      default: () => false,
    },
    dateRange: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: true,
      blockedReasons: null,
      donutOptions: donutChartOptions,
      donutSeries: [],
      donutLegend: [],
      donutOptionsSecond: null,
      donutSeriesSecond: [],
      donutLegendSecond: [],
      titleSecond: null,
    };
  },
  computed: {
    title() {
      return TITLES_BY_TYPE[this.eventType];
    },
    isConversions() {
      return this.eventType === typeValues.CONVERSIONS;
    },
    doubleDonut() {
      return !!this.donutSeries.length && !!this.donutSeriesSecond.length;
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async loadData() {
      if (this.isConversions) this.donutOptionsSecond = donutChartOptions;
      const params = new QueryParamsBuilder();
      if (this.filtersApplied.length) {
        this.filtersApplied.forEach(filter => {
          params.addFilter(filter.name, filter.meta.id);
        });
      }
      switch (this.breakdown) {
        case BREAKDOWN_VALUES.offer:
          params.addFilter('offerId', this.entity.breakdownId);
          break;
        case BREAKDOWN_VALUES.publisher:
          params.addFilter('publisherId', this.entity.breakdownId);
          break;
        case BREAKDOWN_VALUES.advertiser:
          params.addFilter('advertiserId', this.entity.breakdownId);
          break;
      }
      params.addFilter('range_start', getDateISOwithUTC(this.dateRange.startDate));
      params.addFilter('range_end', getDateISOwithUTC(this.dateRange.endDate));
      if (this.isConversions) params.addFilter('event', [typeValues.LEADS, typeValues.POST_EVENTS]);
      else params.addFilter('event', this.eventType);
      try {
        const { data } = await getBlockedReasons(this.activeClientId, params);
        this.blockedReasons = data;
        this.setDonutData();
      } catch {
        this.createToast(Toast.error('Error!', 'We couldn`t load reasons'));
      } finally {
        this.isLoading = false;
      }
    },
    calcPercentage(blockNumber) {
      return `${parseFloat((blockNumber * 100) / this.blockedReasons.total).toFixed(2)}%`;
    },
    setDonutData() {
      if (!this.isConversions) {
        this.formatDonutChart(this.eventType);
      } else {
        if (this.blockedReasons[TYPE_FOR_BACK[typeValues.CONVERSIONS]].length) {
          this.formatDonutChart(typeValues.CONVERSIONS);
        }
        if (this.blockedReasons[TYPE_FOR_BACK[typeValues.POST_EVENTS]].length) {
          this.formatDonutChart(typeValues.POST_EVENTS, true);
        }
      }
    },
    formatDonutChart(eventType, second = false) {
      if (second) this.titleSecond = TITLES_BY_TYPE[eventType];
      const type = TYPE_FOR_BACK[eventType];
      const donutLabels = [];
      this.blockedReasons[type].forEach(block => {
        if (second) {
          this.donutSeriesSecond.push(block.total);
          donutLabels.push(`[${block.Suspicious}] ${block['Reason Type']} (${this.calcPercentage(block.total)})`);
          this.donutLegendSecond.push({
            label: `[${block.Suspicious}] ${block['Reason Type']} (${this.calcPercentage(block.total)}):`,
            value: block.total,
          });
        } else {
          this.donutSeries.push(block.total);
          donutLabels.push(`[${block.Suspicious}] ${block['Reason Type']} (${this.calcPercentage(block.total)})`);
          this.donutLegend.push({
            label: `[${block.Suspicious}] ${block['Reason Type']} (${this.calcPercentage(block.total)}):`,
            value: block.total,
          });
        }
      });
      second ? (this.donutOptionsSecond.labels = donutLabels) : (this.donutOptions.labels = donutLabels);
    },
  },
};
</script>
<style scoped>
.reason-wrapper {
  min-width: 300px;
}
.double-reason-wrapper {
  min-width: 450px;
}
.donut-legend {
  max-height: 250px;
}
.legend-item:last-child {
  @apply border-b-0;
}
</style>
